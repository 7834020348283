  //  ═══╦═════════════════════════════════════╦═══
 //      ║ Social Media Buttons, Small Circles ║ 
//    ═══╩═════════════════════════════════════╩═══

.smallcirclesocialicon {
  background-color: $senary;
  color: white;
  font-size: 1.5em;
  padding: 0.4em 0.4em 0.4em 0.4em;
  border-radius: 50%;

  @extend .borderblackonhover;
}

.smallroundfacebookicon {
  @extend .smallcirclesocialicon;
    &:hover {
      background-color: $facebook-color;
    }
}

.smallroundtwittericon {
  @extend .smallcirclesocialicon;
    &:hover {
      background-color: $twitter-color;
    }
}

.smallroundlinkedinicon {
  @extend .smallcirclesocialicon;
    &:hover {
      background-color: $linkedin-color;
    }
}

.smallroundgoogleplusicon {
  @extend .smallcirclesocialicon;
    &:hover {
      background-color: $googleplus-color;
    }
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║ Social Media Buttons, Large Squares ║ 
//    ═══╩═════════════════════════════════════╩═══

.largesquaresocialicon {
  color: $socialmediaicon-color;
  font-size: 6em;
  padding: .2em .2em .2em .2em;
  float: none;
  //margin: 5px 10px 4px 0;
  margin: 5px 2px 5px 2px;
  text-shadow: 5px 2px 2px rgba(0, 0, 0, 0.16);
  display: inline-block;
  @extend .borderblackonhover;
      &:hover {
      background-color: $brand_green;
    }
      @media screen and (min-width: 1221px) and (max-width: 1250px) {
        font-size: 5.5em;
    }
      @media screen and (min-width: 1127px) and (max-width: 1220px) {
        font-size: 5em;
    }
      @media screen and (min-width: 1051px) and (max-width: 1126px) {
        font-size: 4.5em;
    }
      @media screen and (min-width: 992px) and (max-width: 1050px) {
        font-size: 4em;
    }
      @media screen and (min-width: 460px) and (max-width: 991px) {
        font-size: 6em;
    }
      @media screen and (min-width: 416px) and (max-width: 459px) {
        font-size: 8em;
        margin: 15px;
      }
      @media screen and (min-width: 309px) and (max-width: 415px) {
        font-size: 7.8em;
        margin: 10px;
      }
      @media screen and (min-width: 0) and (max-width: 308px) {
        font-size: 6em;
        margin: 5px;
      }
}

.largesquarefacebookicon {
  @extend .largesquaresocialicon;
  background-color: $facebook-color;
}

.largesquaretwittericon {
  @extend .largesquarefacebookicon;
  background-color: $twitter-color;
}

.largesquarepinteresticon {
  @extend .largesquarefacebookicon;
  background-color: $pinterest-color;
}

.largesquaregoogleplusicon {
  @extend .largesquarefacebookicon;
  background-color: $googleplus-color;
}

// For alternative sizes, please use the fontsize modifier
// Classes in Setup/_modifiers.scss


  //  ═══╦═════════════════════════════════════╦═══
 //      ║   ↓   Green to Blue on Hover   ↓    ║ 
//    ═══╩═════════════════════════════════════╩═══

.greentoblue {
  background-color: $brand-green;
  padding: 0.5em 0 0.5em 0;
  border: 1px solid transparent;
    &:hover {
      background-color: $primary;
      border: 1px solid black;
    }
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║   ↓   Footer newsletter Submit  ↓   ║ 
//    ═══╩═════════════════════════════════════╩═══

.footernewslettersubmitbutton {
  background-color: $brand-green;
  position: relative;
  width: 30%;
  height: 47px;
  z-index: 96;
  float: right;
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║   ↓  Blog Page Read More Button ↓   ║ 
//    ═══╩═════════════════════════════════════╩═══

.blog-post-readmorebutton {
  background-color: $brand_green;
  padding: 1em 4em 1em 4em;
  float: right;
  margin-top: 3em;
  border: 1px solid transparent;
    &:hover {
      background-color: $primary;
     // border: 1px solid black;
    }
}

