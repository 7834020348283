  //  ═══╦═════════════════════════════════════╦═══
 //      ║         ↓   _blog.scss   ↓          ║ 
//    ═══╩═════════════════════════════════════╩═══

  //  ═══╦═════════════════════════════════════╦═══
 //      ║      ↓  single Header Block  ↓      ║ 
//    ═══╩═════════════════════════════════════╩═══

.blog-header-image {
	position: relative;
	margin-bottom: 0.8em;
	img{
		vertical-align:top;
	}
}

.blog-header-image-style {
	width: 100%;
	height: auto;
	display: block;
}

.blog-header-title-background {
	background: rgba($primary, 0.702);
	padding: 1em 1.5em 1em 2em;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
}

.blog-header-title {
	font-size: 2m;
	color: $tertiary;
}




// -----------------------------------------------------

  //  ═══╦═════════════════════════════════════╦═══
 //      ║         ↓  Slider Image  ↓          ║ 
//    ═══╩═════════════════════════════════════╩═══

.blog-mainimage {
	max-height: 250px;
	height: auto;
	width: 100%;
	display: block;
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║     ↓  Main Image Page Title  ↓     ║ 
//    ═══╩═════════════════════════════════════╩═══

.blog-mainimagepagetitle_background {
	background: rgba($primary, 0.702);
	padding: 0.5em 1.5em 0.5em 1.5em;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
}

.blog-mainimagepagetitle_text {
	position: relative;
	margin-bottom: 0.8em;
}


  //  ═══╦═════════════════════════════════════╦═══
 //      ║        ↓  Blog Backgrounds ↓        ║ 
//    ═══╩═════════════════════════════════════╩═══

.blog-page-background {
  background: $tertiary;
  padding: 1.5em 1.5em 1.5em 1.5em;
}

.blog-post-background {
  background-color: $quaternary;
  padding: 0 15px 15px 15px;
  margin-bottom: 1.5em;
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║     ↓   Blog Post Data Styles ↓     ║ 
//    ═══╩═════════════════════════════════════╩═══


.blog-post_date {
	font-size: 1.2em;
	font-family: 'Century Gothic', CenturyGothic, AppleGothic, sans-serif;
}

.blog-post_title {
	font-size: 2.5em;
	font-family: $main-font-bold;
	color: $primary;
}

.blog-post-content {
	font-size: 1.8em;
	font-family: $secondary-font;
	color: $quinary;
		@media screen and (min-width: 0px) and (max-width: 414px) {
			text-align: center;
		}
}

.blog-post-readmoretext {
	color: $tertiary;
	font-size: 2.5em;
	font-family: $main-font-bold;
}

.blog-pagenavigation {
  background: $primary;
  padding: 5px 0 5px 0;
}

.blog-small-center {
	@media screen and (min-width: 0px) and (max-width: 414px) {
		text-align: center;
	}
}

.blog-social-circles {
	float: right;
	margin-top: 1.5em;
	@media screen and (min-width: 0px) and (max-width: 414px) {
		float: none;
		margin-bottom: 2em;
	}
}

.single-post-content{
		h2{
		font-size:2em;
	} 
	h3{
		font-size:1.8em;
	}	
	h4{
		font-size:1.5em;
	}
}