  //  ═══╦═════════════════════════════════════╦═══
 //      ║            _single.scss             ║ 
//    ═══╩═════════════════════════════════════╩═══

  //  ═══╦═════════════════════════════════════╦═══
 //      ║      ↓  single Header Block  ↓      ║ 
//    ═══╩═════════════════════════════════════╩═══

.single-header-image {
	position: relative;
	vertical-align:top;
	img{
		vertical-align:top;
	}
}

.single-header-image-style {
	width: 100%;
	height: auto;
	display: block;
}

.single-header-title-background {
	background: rgba($primary, 0.702);
	padding: 1em 1.5em 1em 2em;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
}

.single-header-title {
	font-size: 2em;
	color: $tertiary;
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║       ↓ Single Post Styles  ↓       ║ 
//    ═══╩═════════════════════════════════════╩═══

.single-post_title {
	font-size: 5em;
	font-family: $main-font_bold;
	color: $primary;
	display: inline-block;
			@media screen and (min-width: 0px) and (max-width: 414px) {
		text-align: center;
	}
}

.single-social-circles {
	float: right;
	margin-top: 1.5em;
	@media screen and (min-width: 0px) and (max-width: 414px) {
		float: none;
		margin-bottom: 2em;
	}
}

.single-post-background {
	background: $quaternary;
	padding: 1.5em;
}

.single-post-content {
	font-size: 2em;
	font-family: $secondary-font;
	p {
		padding-top: 1em;
	}
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║     ↓   Related Posts Styles   ↓    ║ 
//    ═══╩═════════════════════════════════════╩═══

.single-related-posts {
	text-align: center;
	margin: 2em 0 2em 0;
}

.single-related-posts-title-background {
	background: $primary;
	padding: 0.5em;
}

.single-related-posts-title {
	font-family: $main-font_bold;
	font-size: 3em;
	color: $tertiary;
}

.single-related-posts-image-border {
	width: 31%;
	display: inline-block;
	padding: 0.5em;
	margin: 0.5em;
	border: 1px solid #dadcdb;
	border-radius: 4px;
	@media screen and (min-width: 376px) and (max-width: 530px) {
		width: 80%;
	}
	@media screen and (min-width: 0px) and (max-width: 375px) {
		width: 90%;
	}
}

.single-related-posts-overlay {
	text-align: center;
	position: relative;
		&:hover {
			.single-related-posts-overlay-link {
				visibility: visible;
			}
		}
}

.single-related-posts-image-fit {
	vertical-align: top;
}

.single-related-posts-overlay-link {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 100%;
	z-index: 2;
	background: rgba($primary, 0.6);
	//visibility: hidden;
}

.single-related-posts-overlay-link-text {
	font-size: 3em;
	 @media screen and (min-width: 992px) and (max-width: 1100px) {
	 	font-size: 2.8em;
	 }
	 @media screen and (min-width: 707px) and (max-width: 800px) {
	 	font-size: 2.8em;
	 }
	 @media screen and (min-width: 616px) and (max-width: 706px) {
	 	font-size: 2.5em;
	 }
	 @media screen and (min-width: 531px) and (max-width: 615px) {
	 	font-size: 2em;
	 }
	 @media screen and (min-width: 471px) and (max-width: 530px) {
	 	font-size: 5em;
	 }
	 @media screen and (min-width: 426px) and (max-width: 470px) {
	 	font-size: 4.5em;
	 }
	 @media screen and (min-width: 351px) and (max-width: 425px) {
	 	font-size: 4em;
	 }
	 @media screen and (min-width: 0px) and (mac-width: 350px) {
	 	font-size: 3.8em;
	 }
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║   ↓  Single Background Styles  ↓    ║ 
//    ═══╩═════════════════════════════════════╩═══


.single-background {
	background: $tertiary;
	padding: 15px 15px 15px 15px;
}

.single-small-center {
	@media screen and (min-width: 0px) and (max-width: 414px) {
		text-align: center;
	}
}