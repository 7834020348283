.homepagesliderimage {
    height: auto;
    width: 100%;

    //border-bottom: 2px solid white;
    border-bottom: 1px solid transparent;
    display: block;
}

.homepagefeaturedimages {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
}

#carousel-container {
    margin-bottom: 1px;
    position: relative;
    &:hover{
        #next,#prev{
            opacity:0.6;
        }
    }

    #next, #prev {
        position: absolute;
        height: 100%;
        width: 30px;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
        content: ">";
        font-size: 2.4em;
        left: 0;
        line-height: 450px;
        text-align: center;
        cursor: pointer;
        opacity: 0.5;
        font-weight: bold;
        font-family: "roboto";
        z-index: 99;
        opacity:0;

        &:hover {
            opacity: 0.7;
        }
    }

    #slide-2 {
        #feature-one {
            background-image: url("../../img/left.png") !important;
        }

        .main-feature {
            background-image: url("../../img/top-right.png") !important;
        }
    }

    #next {
        left: auto;
        right: 0;
    }

    .carousel-slide {
        position: relative;
    }

    .slide-info {
        position: absolute;
        padding: 20px;
        color: #fff;
        bottom: 50px;
        max-width: 100%;
        text-align: left;
        left: 50px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
        margin: 0;
        padding: 0;
        width: 100%;

        h3 {
            font-size: 5em;

            @media screen and (min-width: 0px) and (max-width: 786px) {
                font-size: 6em;
            }

            span {
                background: rgba(78, 94, 126, 0.8);
                padding:10px 10px 0 10px;
                display:block;
                float:left;
                clear:both;
                line-height:0.8em;
            }
            span + span{
                padding: 5px 10px 0px 10px;
                line-height:1em;
            }
        }

        p {
            text-transform: normal;
            font-size: 1.5em;
            float:left;
            clear:both;
            background:rgba(255,255,255,0.7);
            color:#222;
            font-family: roboto;
            font-size: 2em;
            padding:5px 15px;
            float:left;
            clear:both;
            margin: 0;
        }

        .read-more {
            background: #83b246;
            padding: 5px 20px;
            font-family: 'bebasneuebold';
            color: #fff;
            font-size: 2.6em;
            margin: 10px 0 0 0;
            display: inline-block;
            float:left;
            clear:both;
        }
    }
}

#carousel-container, .caroufredsel_wrapper, #carousel {
    width: 100% !important;
}

//  Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö¼┬¼Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö¼┬¼Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½
//      Ôö£├ÂÔö£ÔûôÔö£┬¬        Ôö£├ÂÔö£├æÔö£Ôöñ   Block Styling   Ôö£├ÂÔö£├æÔö£Ôöñ        Ôö£├ÂÔö£ÔûôÔö£┬¬ 
//    Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö¼┬½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö¼┬½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½

.homepagefeaturedimagestextblock {
    background-color: $primary;
    height: 100%;
    padding: 1em 2em 1em 2em;
    display: inline-block;
    position: relative;
    z-index: 20;
    width: 100%;

    //border-bottom: 2px solid $tertiary;
    clear: both;

    h6 {
        font-size: 2.5em;
        padding: 0em;
        line-height: 0.9em;
        margin: 0;
        letter-spacing: -0.5px;
        z-index: 10;
        position: relative;

        i {
            position: relative;
            top: 3px;
        }
    }
}

.newsandeventsblock {
    background-color: $quaternary;
    z-index: 60;
    height: 100%;
    padding: 1.7em 2em 1.7em 2em;
}

.newsandeventstextstyling {
    display: inline-block;
    vertical-align: top;
}

.homepageeventitemblock {
    padding-bottom: 1em;

    @media screen and (max-width: 991px) {
        text-align: center;
    }

    &:hover {
        opacity: 0.9;
    }
}

.homepageeventblockbackground {
    //background-color: $tertiary;
}

//  Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö¼┬¼Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö¼┬¼Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½
//      Ôö£├ÂÔö£ÔûôÔö£┬¬  Ôö£├ÂÔö£├æÔö£Ôöñ  Featured Images Navigation   Ôö£├ÂÔö£├æÔö£Ôöñ  Ôö£├ÂÔö£ÔûôÔö£┬¬ 
//    Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö¼┬½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö¼┬½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½Ôö£├ÂÔö£ÔûôÔö£├½

.homepage-featuredimage_one {
    @include border-around(0px, 1px, 1px, 0px, transparent);
}

.homepage-featuredimage_two {
    @include border-around(0px, 1px, 1px, 0px, transparent);
}

.homepage-featuredimage_three {
    @include border-around(0px, 1px, 1px, 0px, transparent);
}

.homepage-featuredimage_four {
    @include border-around(0px, 1px, 1px, 0px, transparent);
}

.feature-box {
    position: relative;

    * {
        -webkit-transition: all 2s ease-in-out;
        -o-transition: all 2s ease-in-out;
        transition: all 2s ease-in-out;
    }

    &:hover {
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;

        &:after {
            opacity: 1;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
            -webkit-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
        }
    }

    &:after {
        top: 50%;
        position: absolute;
        width: 120px;
        content: "Find Out More";
        background: #83b246;
        color: #fff;
        opacity: 0;
        -webkit-transform: translate(0, -40%);
        -ms-transform: translate(0, -40%);
        -o-transform: translate(0, -40%);
        transform: translate(0, -40%);
        font-size: 1.8em;
        left: 50%;
        margin-left: -76px;
        padding: 10px;
        text-align: center;
        margin-top: -20px;
        font-family: 'bebasneuebold';
        pointer-events: none;
    }

    overflow: hidden;

    &:hover {
        img {
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
            transform: scale(1.1);
        }
    }
}

.homepage-featuredimages_right {
    display: inline-block;
}

.homepage-featuredimages_left {
    float: left;
    display: inline-block;
    height: auto;
}

.advancedcustomfieldspro {}

.homepage-news_paragraph {
    @media screen and (min-width: 601px) and (max-width: 991px) {
        margin-left: 45px;
        margin-right: 45px;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 600px) {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}

.homepage-news_title {
    @media screen and (max-width: 991px) {
        font-size: 4em;
        margin-top: 20px;
    }
}

.homepage-comingsoon-block {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.homepage-kt {
    margin-top: 30px;
    margin-bottom: 30px;
}