  //  ═══╦═════════════════════════════════════╦═══
 //      ║         ↓   _contact.scss   ↓          ║ 
//    ═══╩═════════════════════════════════════╩═══

  //  ═══╦═════════════════════════════════════╦═══
 //      ║        ↓  Backgroundstyles ↓        ║ 
//    ═══╩═════════════════════════════════════╩═══

.contact-page_background {
	background-color: $tertiary;
}

.contact-page-form_background {
	background: $quaternary;
	margin-top: 1.5em;
	padding: 1.5em;
}

#contact-form label {
	color: $primary;
	font-size: 2.4em;
	font-family: $main-font_bold;
}

#contact-form input {
	color: $brand_grey;
	font-family: 'roboto';
	font-size: 2em;
}

form textarea, form input {
	color: $primary;
	font-size: 2em;
	font-family: $secondary_font;
}

#submit-button_green {
	width: 100%;
	background: $brand_green;
	border: 1px solid black;
	color: $tertiary !important;
		&:hover {
			background: $tertiary;
			color: $brand_green !important;
		}
}

.contact-page_informationbox {
	background: $primary;
	height: auto;
}

.contact-page-informationbox_text {
	font-size: 2.5em;
	color: $tertiary;
	font-family: $secondary-font;
	padding: 15px 40px 15px 40px;
	text-align: center;
		@media screen and (max-width: 479px) {
			font-size: 2em;
		}
	}

.contact-sidebar-followus_text {
	background-color: $primary;
	color: $tertiary;
	text-align: center;
	padding: 15px 15px 15px 15px;
}

.contact-sidebar-joinourmailinglist_text {
	padding: 10px 0 10px 0;
	text-align: center;
}