  //  ═══╦═════════════════════════════════════╦═══
 //      ║              Footer.scss            ║ 
//    ═══╩═════════════════════════════════════╩═══

.footer{
	position: relative;
	//margin-top: -4em;
	//padding: 3.4em 0 10em 2.6em;
	background: rgb(78, 93, 128);
	background-image: url('../../img/footerbackgroundimage.png');
	background-repeat: no-repeat;
	background-position: right;
	border-top: 5px solid rgb(131, 178, 70);
		/*&:after{
			content:"";
			width:100%;
			height:3.7em;
			background-image:url("../../img/footerimage.png");
			background-repeat:repeat-x;
			position:absolute;
			background-position: center top;
			top:-37px;
			left:0;
		}*/
		ul{
			li{
				  font-size: 1.4em;
				  font-family: "Roboto";
				  color: $tertiary;
				  line-height: 1.6;
				  &:before {
				  	content: "+ " ;
				  	color: #83b246;
				  }


			}
		}
}

.footer {
        	@media screen and (max-width: 991px) {
        		background-image: none;
        	}	
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║         ↓   Footer Logo   ↓         ║ 
//    ═══╩═════════════════════════════════════╩═══

.footerlogo {}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║    ↓   Footer Bootom Content   ↓    ║ 
//    ═══╩═════════════════════════════════════╩═══

.footerbottomcontentbackground {
  background-color: white;
  padding: 2.5em 4em 0em 4em;
}

.footerverticalcontentborder {
        border-right: 1px solid #c5c5d7;
        margin-bottom: 2em;
        padding-bottom: 2em;
        	@media screen and (max-width: 991px) {
        		border-right: 1px solid transparent;
        	}
}

.footernewsletterinputbox {
  background-color: rgb(197, 197, 215);
  position: absolute;
  width: 67%;
  height: 47px;
  z-index: 96;
}

.footericonslogos {
	@media only screen and (max-width: 991px) and (min-width: 200px) {
		margin-top: 4em;
	}
}

.footersocial {
	text-align: center;
	margin-left: -26px;
}

.footer-partner_logos {
  width: auto;
  display: inline-block;
	@media screen and (min-width: 992px) and (max-width: 1143px) {
		width: 33%;
	}
}

.col-md-12.footerbottomcontentbackground {
	margin-top: -3px;
}