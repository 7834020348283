@include keyframes(sonarEffect) {
    0% {
        opacity: 0.3;
    }
    40% {
        opacity: 0.5;
        -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 20px 5px $primary, 0 0 0 10px rgba(255, 255, 255, 0.5);
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 20px 5px $primary, 0 0 0 10px rgba(255, 255, 255, 0.5);
    }
    100% {
        -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 20px 5px $primary, 0 0 0 10px rgba(255, 255, 255, 0.5);
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 20px 5px $primary, 0 0 0 10px rgba(255, 255, 255, 0.5);
        @include transform(scale(1.3));
        opacity: 0;
    }
}


@include keyframes(spin) {
    0% {
        @include transform(rotate(0deg));
    }
    100% {
        @include transform(rotate(360deg));
    } 
}


@include keyframes(comeDown) {
    0% {
        opacity: 0;
        @include transform(translate3d(0, -20px, 0));
    }
    100% {
        opacity: 1;
        visibility: visible;
        @include transform(translate3d(0, 0px, 0));
    }
}


@include keyframes(comeUp) {
    0% {
        opacity: 0;
        @include transform(translate3d(0, 20px, 0));
    }
    100% {
        opacity: 1;
        visibility: visible;
        @include transform(translate3d(0, 0px, 0));
    }
}


@include keyframes(ninja) {
    0% {
        right: 80px;
        @include transform(rotate(-20deg));
    }
    100% {
        right: -10px;
        @include transform(rotate(0deg));
    }
}


@include keyframes(sonar) {
    0% {
        right: 80px;
        @include transform(rotate(-20deg));
    }
    100% {
        right: -10px;
        @include transform(rotate(0deg));
    }
}


@include keyframes(menuFadeIn) {
    0% {
        opacity: 0;
        visibility: hidden;
        @include transform(translate(10px, 0) translateZ(0));
    }
    100% {
        opacity: 1;
        @include transform(translate(0px, 0) translateZ(0));
        visibility: visible;
    }
}


@include keyframes(menuFadeUp) {
    0% {
        opacity: 0;
        visibility: hidden;
        @include transform(translate(0, -10px) translateZ(0));
    }
    100% {
        opacity: 1;
        @include transform(translate(0px, 0) translateZ(0));
        visibility: visible;
    }
}


@include keyframes(spinopposite) {
    0% {
        @include transform(rotate(0deg));
    }
    100% {
        @include transform(rotate(-360deg));
    }
}


@include keyframes(appear) {
    0% {
        margin-top: 400px;
        @include transform(rotate(-4deg));
    }
    100% {
        margin-top: 0px;
        @include transform(rotate(5deg));
    }
}


@include keyframes(grow1) {
    0% {
        height: 33%;
    }
    50% {
        height: 100%;
    }
    100% {
        height: 33%;
    }
}


@include keyframes(grow2) {
    0% {
        height: 66%;
    }
    50% {
        height: 100%;
    }
    100% {
        height: 66%;
    }
}


@include keyframes(grow3) {
    0% {
        height: 16%;
    }
    50% {
        height: 100%;
    }
    100% {
        height: 16%;
    }
}


@include keyframes(skills) {
    0% {
        width: 25%;
    }
    20% {
        width: 100%;
    }
    80% {
        width: 100%;
    }
    100% {
        width: 25%;
    }
}


@include keyframes(rocket) {
    1% {
        opacity: 1;
        @include transform(translate(-300px, 100px) rotate(-20deg));
    }
    81% {
        opacity: 1;
        @include transform(rotate(0deg));
    }
    100% {
        @include transform(translate(500px, -180px));
        opacity: 0.5;
    }
}


@include keyframes(track) {
    0% {
        opacity: 1;
        background-position: center -1287px;
    }
    100% {
        background-position: center 0px;
    }
}


@include keyframes(opa) {
    0% {
        opacity: 1;
    }
    50%,100% {
        opacity: 0;
    }
}


@include keyframes(fadeIn) {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}


@include keyframes(shake) {
    0% {
        @include transform(rotate(0deg));
    }
    25% {
        @include transform(rotate(5deg));
    }
    75% {
        @include transform(rotate(-5deg));
    }
    100% {
        @include transform(rotate(0deg));
    }
}


@include keyframes(rise) {
    0% {}
    50% {}
    100% {
        @include transform(translate(0, -20px));
    }
}


@include keyframes(fall) {
    0% {
        @include transform(translate(0, -20px));
    }
    50% {
        @include transform(translate(0, 7px));
    }
    60% {
        @include transform(translate(0, 0px));
    }
    80% {
        @include transform(translate(0, 4px));
    }
    100% {
        @include transform(translate(0, 0));
    }
}


@include keyframes(left-slide) {
    0% {
        @include transform(translate(-100%, 0));
    }
    100% {
        @include transform(translate(0, 0));
    }
}


@include keyframes(right-slide) {
    0% {
        @include transform(translate(100%, 0));
    }
    100% {
        @include transform(translate(0, 0));
    }
}


@include keyframes(pulse) {
    50% {
        opacity: 0.6;
    }
    0,100% {
        opacity: 1;
    }
}


@include keyframes(move-up) {
    0% {
        @include transform(translate(0, 100px));
    }
    100% {
        opacity: 1;
        @include transform(translate(0, 0));
    }
}


@include keyframes(rota) {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


@include keyframes(opa2) {
    0% {
        opacity: 1;
        border-top-left-radius: 120px;
        border-bottom-left-radius: 120px;
    }
    50%, 100% {
        opacity: 0;
    }
}


@include keyframes(opa) {
    0% {
        opacity: 1;
    }
    50%, 100% {
        opacity: 0;
    }
}


.rise {
    @include animation(rise, 0s, 0.3s, ease, 1);
}

.fall {
    @include animation(fall, 0s, 0.3s, linear, 1);
}