$orange: #e4511e;

@font-face {
    font-family: 'proxima_novaextrabold';
    src: url("../../fonts/proximanova-extrabold-webfont.eot");
    src: url("../../fonts/proximanova-extrabold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-extrabold-webfont.woff") format("woff"), url("../../fonts/proximanova-extrabold-webfont.ttf") format("truetype"), url("../../fonts/proximanova-extrabold-webfont.svg#proxima_novaextrabold") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'proxima_novablack';
    src: url("../../fonts/proximanova-black-webfont.eot");
    src: url("../../fonts/proximanova-black-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-black-webfont.woff") format("woff"), url("../../fonts/proximanova-black-webfont.ttf") format("truetype"), url("../../fonts/proximanova-black-webfont.svg#proxima_novablack") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'proxima_nova_rgbold';
    src: url("../../fonts/proximanova-bold-webfont.eot");
    src: url("../../fonts/proximanova-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-bold-webfont.woff") format("woff"), url("../../fonts/proximanova-bold-webfont.ttf") format("truetype"), url("../../fonts/proximanova-bold-webfont.svg#proxima_nova_rgbold") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'proxima_novasemibold';
    src: url("../../fonts/proximanova-semibold-webfont.eot");
    src: url("../../fonts/proximanova-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-semibold-webfont.woff") format("woff"), url("../../fonts/proximanova-semibold-webfont.ttf") format("truetype"), url("../../fonts/proximanova-semibold-webfont.svg#proxima_novasemibold") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url("../../fonts/proximanova-regular-webfont.eot");
    src: url("../../fonts/proximanova-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-regular-webfont.woff") format("woff"), url("../../fonts/proximanova-regular-webfont.ttf") format("truetype"), url("../../fonts/proximanova-regular-webfont.svg#proxima_nova_rgregular") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'proxima_novalight';
    src: url("../../fonts/proximanova-light-webfont.eot");
    src: url("../../fonts/proximanova-light-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-light-webfont.woff") format("woff"), url("../../fonts/proximanova-light-webfont.ttf") format("truetype"), url("../../fonts/proximanova-light-webfont.svg#proxima_novalight") format("svg");
    font-weight: normal;
    font-style: normal;
}


@include keyframes(speech-frame) {
    0% {
        opacity: 0;
        -webkit-transform: translate(0%, -100%) scale(1) rotate(0deg);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0%, 0%) scale(1) rotate(0deg);
    }
}


@include keyframes(parker) {
    0% {
        opacity: 1;
        -webkit-transform: rotate(20deg) translate(-200%, 0%);
    }
    92% {
        opacity: 1;
        -webkit-transform: rotate(0deg) translate(3%, 0%);
    }
    95% {
        opacity: 1;
        -webkit-transform: rotate(0deg) translate(-1%, 0%);
    }
    100% {
        opacity: 1;
        -webkit-transform: rotate(0deg) translate(0%, 0%);
    }
}


@include keyframes(eyes) {
    0% {
        opacity: 1;
        -webkit-transform: translate(0px, 0%);
    }
    50% {
        opacity: 1;
        -webkit-transform: translate(-100%, 0%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0%);
    }
}


@include keyframes(text) {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


#csPage {
    background: $orange;
    height: 100%;
    width: 100%;
    #page {
        margin: 0 auto;
    }
    .page-width {
        width: 1080px;
    }
    #head {
        padding: 20px;
    }
    #nav {
        ul {
            li {
                display: inline-block;
            }
        }
    }
    .container {
        position: absolute;
        height: 100%;
        min-height: 100%;
        min-width: 100%;
        left: 0;
        top: 0;
        width: 100%;
        #speech_bubble {
            background-image: url("../../csimg/speech-bubble.png");
            width: 50%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            content: "";
            height: 60.5%;
            margin-top: -20%;
            position: absolute;
            left: 50%;
            color: #fff;
            text-align: center;
            right: 50%;
            font-family: 'proxima_novaextrabold';
            top: 50%;
            margin-left: -25%;
            bottom: 50%;
            h3 {
                position: absolute;
                top: 85%;
                right: 2.5%;
                width: 65%;
                text-align: Right;
                line-height: 1.1em;
                font-family: 'proxima_novasemibold';
            }
            h2 {
                text-align: Center;
                margin: 0 auto;
                font-size: 1em;
                text-align: center;
                letter-spacing: -2px;
                width: 100%;
                height: 100%;
                margin-top: -6.5%;
            }
        }
    }
    #footer {
        position: absolute;
        right: 0;
        bottom: 0;
        text-align: right;
        margin: 20px;
        img {
            width: 40%;
        }
    }
    #parker {
        width: 55%;
        height: 55%;
        position: absolute;
        left: -17.5%;
        bottom: -5%;
    }
    .outerContainer {
        display: table;
        width: 100px;
        /* width of parent */
        height: 100%;
        overflow: hidden;
    }
    .outerContainer .innerContainer {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    #parker {
        opacity: 0;
        @include animation(parker, 0s, 2s, linear, 1);
    }
    #speech_bubble {
        opacity: 0;
        @include animation(speech-frame, 2s, 0.83s, ease-in-out, 1);
    }
    #speech_bubble h2 {
        opacity: 0;
        @include animation(text, 3s, 2.33s, ease-in-out, 1);
    }
    #speech_bubble h3 {
        opacity: 0;
        @include animation(text, 4s, 2.33s, ease-in-out, 1);
    }
}

#blogPostPage {
    background: $orange;
    height: 100%;
    width: 100%;
    #page {
        margin: 0 auto;
    }
    .page-width {
        width: 1080px;
    }
    #head {
        padding: 20px;
    }
    #nav {
        ul {
            li {
                display: inline-block;
            }
        }
    }
    .container {
        position: absolute;
        height: 100%;
        min-height: 100%;
        min-width: 100%;
        left: 0;
        top: 0;
        width: 100%;
        #speech_bubble {
            background-image: url("../../csimg/speech-bubble.png");
            width: 50%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            content: "";
            height: 60.5%;
            margin-top: -20%;
            position: absolute;
            left: 50%;
            color: #fff;
            text-align: center;
            right: 50%;
            font-family: 'proxima_novaextrabold';
            top: 50%;
            margin-left: -25%;
            bottom: 50%;
            h3 {
                position: absolute;
                top: 85%;
                right: 2.5%;
                width: 65%;
                text-align: Right;
                line-height: 1.1em;
                font-family: 'proxima_novasemibold';
            }
            h2 {
                text-align: Center;
                margin: 0 auto;
                font-size: 1em;
                text-align: center;
                letter-spacing: -2px;
                width: 100%;
                height: 100%;
                margin-top: -6.5%;
            }
        }
    }
    #footer {
        position: absolute;
        right: 0;
        bottom: 0;
        text-align: right;
        margin: 20px;
        img {
            width: 40%;
        }
    }
    #parker {
        width: 55%;
        height: 55%;
        position: absolute;
        left: -17.5%;
        bottom: -5%;
    }
    .outerContainer {
        display: table;
        width: 100px;
        /* width of parent */
        height: 100%;
        overflow: hidden;
    }
    .outerContainer .innerContainer {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    #parker {
        opacity: 0;
        @include animation(parker, 0s, 2s, linear, 1);
    }
    #speech_bubble {
        opacity: 0;
        @include animation(speech-frame, 2s, 0.83s, ease-in-out, 1);
    }
    #speech_bubble h2 {
        opacity: 0;
        @include animation(text, 3s, 2.33s, ease-in-out, 1);
    }
    #speech_bubble h3 {
        opacity: 0;
        @include animation(text, 4s, 2.33s, ease-in-out, 1);
    }
}

@media only screen and (min-width: 200px) and (max-width: 500px) {
    #csPage .container #speech_bubble h3 {
       display:none;

    }
}

