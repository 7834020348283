.by-month {
    margin: 0 0 2em 0;
    width: 100%;
    display: inline-block;

    li {
        float: left;
        background: #fff;
        background-color: #4e5d80;
        font-family: 'bebasneuebold';
        border-right: 1px solid #5D6B8A;
        border-left: 1px solid #455372;

        &:last-child {
            border-right: 0;
        }

        &:first-child {
            border-left: 0;
        }

        a {
            color: #fff;
            padding: 5px 13px;
            font-size: 3em;
            display: inline-block;
        }

        &.selected {
            background: #83b246;
        }
    }
}

.event-list {
    img {
        float: right;
        max-width: 20%;
    }

    .event {
        background: #c5c5d7;
        margin: 0 0 1em 0;
        padding: 1em;

        h2 {
        	margin:0.21em 0;
        	color:#4e5d80;

        }

        p {
            font-family: 'roboto';
            font-size: 1.6em;
            padding: 0 0 10px 0;
            color:#4e5d80;

        }

        .button {
            background: #83b246;
            color: #fff;
            padding: 5px 25px;
            display: inline-block;
            font-size: 2em;
            font-family: 'bebasneuebold';
        }

        header h3 {
            width: 100%;
color:#4e5d80;
font-family:roboto;
font-size:1.9em;
            .featured-date {
                background: #83b246;
                color: #fff;
                font-family: 'bebasneuebold';
                margin-right: 20px;
                padding:0 5px;
                font-size:1.5em;

                strong {
                    font-size: 0.7em;
                }
            }
            .event-time{
            	margin-left:20px;
            }

        }
    }
}

.ui-datepicker-inline {
    width: 100%;
    margin: 0 0 2em 0;

    table {
        width: 100%;
        background: white;

        td, th {
            border: 1px solid #ddd;
            text-align: center;
            font-family: 'roboto';
            font-weight: bold;
            font-size: 1.4em;
            line-height: 40px;
            height: 40px;
            vertical-align: middle;
            width: 14.28%;

            &.ui-datepicker-other-month {
                background: #fff !important;
                border: 0;
            }

            &[title="Available"] {
                background: #83b246 !important;
            }
        }

        td {
            &:nth-child(odd) {
                background: #eee;
            }
        }

        tr:nth-child(odd) {
            td {
                &:nth-child(odd) {
                    background: #fff;
                }

                &:nth-child(even) {
                    background: #eee;
                }
            }
        }

        th {
            background: #c5c5d7;
            border: 0;
            text-transform: uppercase;
            line-height: 25px;
            height: 25px;
            color: #4e5d80;
        }
    }
}

.ui-datepicker-prev, .ui-datepicker-next {
    position: absolute;
    left: 20px;
    top: 18px;
    font-size: 1.8em;
    color: #fff;
    cursor: pointer;
}

.ui-datepicker-next {
    left: auto;
    right: 20px;
}

.ui-datepicker-header {
    position: relative;
}

.ui-datepicker-title {
    background-color: #4e5d80;
    color: #fff;
    text-align: center;
    font-size: 4em;
    padding: 5px 10px;
    font-family: 'bebasneuebold';
}