@mixin gradient($baseColor, $toColor) {
    background-color: $baseColor;
    background-image: -moz-linear-gradient($baseColor, $toColor);
    background-image: -webkit-gradient($baseColor, $toColor);
    background-image: linear-gradient($baseColor, $toColor);
}

@mixin animation($animation, $delay, $duration, $timing, $count) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-timing-function: $timing;
    -webkit-animation-iteration-count: $count;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-timing-function: $timing;
    -moz-animation-iteration-count: $count;
    -moz-animation-fill-mode: forwards;
    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;
    -o-animation-timing-function: $timing;
    -o-animation-iteration-count: $count;
    -o-animation-fill-mode: forwards;
    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-timing-function: $timing;
    animation-iteration-count: $count;
    animation-fill-mode: forwards;
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @-ms-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.class {
    @include animation(rotation, 0s, 1.5s, linear, 1);
}

#back-to-top {
    position: fixed;
    bottom: 30px;
    display: none;
    right: 30px;
}

@mixin button{
    background:$primary;
    outline:0;
    border:0;
        -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    position: relative;
        &:hover {
        opacity: 0.8;
        background-repeat: no-repeat;
        background-size: 10px 15px;
    }
    &:active {
        top: 1px;
    }
}

.text-center{
    text-align:center;
}

.float_right {
    float: right;
}

.float_left {
    float: left;
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║     ↓   Border Around Mixin  ↓      ║ 
//    ═══╩═════════════════════════════════════╩═══

@mixin border-around($border_top, $border_right, $border_bottom, $border_left, $border-color) {
  border-top: $border_top solid $border-color;
  border-right: $border_right solid $border-color;
  border-bottom: $border_bottom solid $border-color;
  border-left: $border_left solid $border-color;
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║   ↓   hover border solid black  ↓   ║ 
//    ═══╩═════════════════════════════════════╩═══

.borderblackonhover {
    border: 1px solid transparent;
        &:hover {
           // border: 1px solid black;
        }
}