  //  ═══╦═════════════════════════════════════╦═══ 
 //      ║     ↓   Font Size Modifiers   ↓     ║ 
//    ═══╩═════════════════════════════════════╩═══ 

.fontsize-em_1 {
  font-size: 1em;
}

.fontsize-em_2 {
  font-size: 2em;
}

.fontsize-em_3 {
  font-size: 3em;
}

.fontsize-em_4 {
  font-size: 4em;
}

.fontsize-em_5 {
  font-size: 5em;
}

.fontsize-em_6 {
  font-size: 6em;
}

  //  ═══╦═════════════════════════════════════╦═══ 
 //      ║       ↓   Width Modifiers   ↓       ║ 
//    ═══╩═════════════════════════════════════╩═══ 

.max-width_25 {
	max-width: 25%;
}

.max-width_50 {
	max-width: 50%;
}

.max-width_75 {
	max-width: 75%;
}

.max-width_100 {
	max-width: 100;
}

  //  ═══╦═════════════════════════════════════╦═══ 
 //      ║      ↓   Padding Modifiers   ↓      ║ 
//    ═══╩═════════════════════════════════════╩═══ 

.padding-top-em_0 {
	padding-top: 0;
}

.padding-right-em_0 {
	padding-right: 0;
}

.padding-bottom-em_0 {
	padding-bottom: 0;
}

.padding-left-em_0 {
	padding-left: 0;
}

.padding-top-em_1 {
	padding-top: 1em;
}

.padding-right-em_1 {
	padding-right: 1em;
}

.padding-bottom-em_1 {
	padding-bottom: 1em;
}

.padding-left-em_1 {
	padding-left: 1em;
}


  //  ═══╦═════════════════════════════════════╦═══ 
 //      ║       ↓   Margin Modifiers   ↓      ║ 
//    ═══╩═════════════════════════════════════╩═══ 

.margin-top-em_1 {
	margin-top: 1em
}

.margin-right-em_1 {
	margin-right: 1em;
}

.margin-bottom-em_1 {
	margin-bottom: 1em;
}

.margin-left-em_1 {
	margin-left: 1em;
}

.margin-top-em_1--5 {
	margin-top: 1.5em;
}

.margin-right-em_1--5 {
	margin-right: 1.5em;
}

.margin-bottom-em_1--5 {
	margin-bottom: 1.5em;
}

.margin-left-em_1--5 {
	margin-left: 1.5em;
}

  //  ═══╦═════════════════════════════════════╦═══ 
 //      ║       ↓   Float Modifiers   ↓       ║ 
//    ═══╩═════════════════════════════════════╩═══ 

.float-right {
	float: right !important;
}

.float-left {
	float: left !important;
}