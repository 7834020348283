// Grid - v0.1

.full-width, .full {
    width: 100%;
    vertical-align: top;
    float: left;
}

.half-width, .half {
    width: 49%;
    margin-right: 1%;
    float: left;
}

.two-third {
    width: 65.66%;
    margin-right: 0%;
    float: left;
}

.third {
    width: 32.66%;
    margin-right: 1%;
    float: left;
}

.fourth {
    width: 24.33%;
    margin-right: 1%;
    float: left;
}

.fifth {
    width: 19.33%;
    margin-right: 1%;
    float: left;
}

.sixth {
    width: 16%;
    margin-right: 1%;
    float: left;
}

.seventh {
    width: 14%;
    margin-right: 1%;
}

.seventh, .sixth, .fifth,  .fourth, .third, .two-thirds, .half-width, .half, .full-width, .full {
    float: left;
    &:last-child {
        margin-right: 0;
    }
    &.right {
        float: right;
    }
    &.left {
        float: left;
        clear: both;
    }
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
    .hide-mobile {
        display: none;
    }
    .seventh, .sixth, .fifth,  .fourth, .third, .two-thirds, .half-width, .half, .full-width, .full {
        width:100%;
    }
}