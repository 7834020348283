#nav {
    background-color: rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0em 0 0em 0em;

    #nav-container > ul {
        position: relative;
        text-align: center;

        > li {
            display: inline-block;
            font-size: 2.7em;
            font-family: $main-font_bold;
            color: $primary;
            padding-right: 30px;
            z-index: 99999;
            letter-spacing: -0.75px;
            margin-bottom: -5px;

            &:hover {
                background: $primary;
                color: #fff;

                li, a {
                    color: #fff;
                }

                &:after {
                    color: white !important;
                }
            }

            > a {
                display: block;
                width: 100%;
                text-indent: 20px;
                height: 100%;
                padding: 0.5em 0 0 0;
                text-align: left;
            }

            &:hover {
                ul {
                    display: block;
                }
            }

            &:after {
                content: "\00a0 ";
                font-size: 0.44em;
                display: block;
                letter-spacing: -0.5px;
            }

            &.menu-item:after {
                padding-bottom: 1.6em;
                float: left;
                display: inline-block;
            }

            &.menu-item-61:after {
                content: "Fun For All The Family \25bc";
                color: #262626;
                position: relative;
                font-family: $secondary-font;
                padding-left: 20px;
                padding-bottom: 1.3em;
            }

            &.menu-item-60:after {
                content: "Explore The Country Estate \25bc";
                color: #262626;
                padding-left: 20px;
                padding-bottom: 1.3em;
                font-family: $secondary-font;
            }

            &.menu-item-59:after {
                padding-bottom: 1.3em;
                content: "Our History \0026  More \25bc";
                color: #262626;
                padding-left: 20px;
                font-family: $secondary-font;
            }

            &.menu-item-781:after {
                padding-bottom: 1.3em;
                content: "Find out the Latest \25bc";
                color: #262626;
                padding-left: 20px;
                font-family: $secondary-font;
            }

            &.menu-item-784:after {
                padding-bottom: 1.3em;
                content: "Get in Touch \25bc";
                color: #262626;
                padding-left: 20px;
                font-family: $secondary-font;
            }

            @media screen and (min-width: 768px) and (max-width: 1080px) {
                font-size: 1.8em;
                padding-right: 0px;
            }

            @media only screen and (min-width: 1080px) and (max-width: 1250px) {
                padding-right: 0px;
            }

            > ul {
                display: none;
                position: absolute;
                left: 0;
                top: 100px;
                background: $primary;
                width: 100%;
                text-indent: -99999px;
                padding-bottom: 20px;
                z-index: 99999;
                text-align: left;
                text-align: center;
                box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.4);
                vertical-align: top;

                &:before,
                &:after {
                    content: "";
                    display: table;
                }

                .menu-column {
                    width: 14%;
                    display: inline-block;
                    font-size: 0.9em;
                    position: relative;
                    padding: 0 5px;
                    font-size: 0.9em;
                    vertical-align: top;

                    img {
                        max-width: 100%;
                        height: auto;
                    }

                    &:after {
                        clear: both;
                    }
                }

                li > ul {
                    .menu-photo {
                        width: 100%;
                        height: 100px;
                        content: "";
                        background-size: 100%;
                        background-size: cover;
                        font-size: 0;
                        display: inline-block;
                        margin-bottom: 10px;
                        float: left;
                        background-position: center center;

                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }

                        &:after {
                            display: none;
                        }
                    }

                    .menu-tea-photo {
                        background-image: url("../../img/tearoom.jpg");
                    }

                    .menu-gardens-photo {
                        background-image: url("../../img/gardens.jpg");
                    }

                    .menu-woodlands-photo {
                        background-image: url("../../img/woodlands.jpg");
                    }

                    .menu-visitor-centre-photo {
                        background-image: url("../../img/visitor-centre.jpg");
                    }

                    .menu-visitor-photo {
                        background-image: url("../../img/visitor.jpg");
                    }

                    .menu-admissions-photo {
                        background-image: url("../../img/admissions.jpg");
                    }

                    .menu-schools-photo {
                        background-image: url("../../img/schools.jpg");
                    }

                    .menu-children-photo {
                        background-image: url("../../img/families.jpg");
                    }

                    .menu-falconry-photo {
                        background-image: url("../../img/falconry.jpg");
                    }

                    .menu-finlaystone-photo {
                        background-image: url("../../img/house.jpg");
                    }

                    .menu-macmillan-photo {
                        background-image: url("../../img/clan.jpg");
                    }

                    .menu-history-photo {
                        background-image: url("../../img/history.jpg");
                    }

                    .menu-rangers-photo {
                        background-image: url("../../img/ranger.jpg");
                    }

                    .menu-facilities-photo {
                        background-image: url("../../img/facilities.jpg");
                    }

                    .menu-play-areas-photo {
                        background-image: url("../../img/play-area.jpg");
                    }

                    .menu-weddings-photo {
                        background-image: url("../../img/wedding.png");
                    }

                    .menu-ranger-led-photo {
                        background-image: url("../../img/ranger.jpg");
                    }

                    .menu-corporate-photo {
                        background-image: url("../../img/corporate.jpg");
                    }

                    .menu-gallery-photo {
                        background-image: url("../../img/woodlands.jpg");
                    }

                    .menu-events-photo {
                        background-image: url("../../img/schools.jpg");
                    }

                    .menu-news-photo {
                        background-image: url("../../img/house.jpg");
                    }

                    .menu-contact-photo {
                        background-image: url("../../img/gardens.jpg");
                    }

                    .menu-directions-photo {
                        background-image: url("../../img/admissions.jpg");
                    }

                    .menu-feedback-photo {
                        background-image: url("../../img/woodlands.jpg");
                    }

                    li {
                        color: #fff;
                        text-indent: 0px;
                        font-size: 1em;
                        line-height: 0.9em;
                        text-align: left;

                        &:after {
                            background-image: url("../../img/right-arrow.png");
                            width: 11px;
                            height: 17px;
                            display: inline-block;
                            margin-left: 10px;
                            background-repeat: no-repeat;
                            content: "";
                        }
                    }
                }
            }
        }
    }
}

.top-bar {
    position: relative;
    background: #4E5E7E;
    clear: both;
    text-align: center;
    color: #fff;

    h2 {
        font-size: 1.4em;
    }
}

.toolbarfeaturedblock {
    background-color: #415176;
    float: right;
    top: 0px;
    width: auto;
    padding: 2.5em 2.1em 2.5em 2.1em;
    z-index: 3;
    margin-left: 1px;

    &:hover {
        background-color: rgb(41, 128, 185);

        img {
            -webkit-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            -webkit-transform: rotate(5deg);
            -ms-transform: rotate(5deg);
            -o-transform: rotate(5deg);
            transform: rotate(5deg);
        }
    }
}

.navigationaltagline {
    font-family: snellround;
    float: right;
    color: rgb(78, 93, 128);
    font-size: 4em;
    margin-top: 0.1em;
    text-align: left;
    margin-right: 90px;
}

.navigationaltaglineblockleft {
    display: inline-block;
    float: left;

    @media screen and (max-width: 1072px) {
        float: none;
        text-align: center;
    }
}

.navigationaltaglineblockright {
    float: right;
    width: 69%;

    @media screen and (max-width: 1072px) {
        float: none;
        width: 100%;
        text-align: center;
    }
}

#logo {
    max-width: 30%;
    min-width: 22em;
    z-index: 3;
}

.preventClick, .preventClick > a {
    cursor: default;
}

.find-us-box {
    float: left;
    width: 34%;
    font-family: 'bebasneuebold';
    padding: 8em 0 0 2em;

    @media screen and (min-width: 768px) and (max-width: 1080px) {
        padding-top: 1em;
        font-size: 0.8em;

        div {
            margin-bottom: 10px;
        }
    }

    @media only screen and (min-width: 1080px) and (max-width: 1250px) {
        padding-top: 2em;

        div {
            margin-bottom: 10px;
        }
    }

    img {
        display: inline-block;
        vertical-align: middle;
        padding: 0 0.5em 0 0;
        max-width: 30px;
        height: auto;
    }

    h2 {
        display: inline-block;
        padding: 0 1em 0 0;
        vertical-align: middle;
        font-size: 2.5em;
    }

    .telephone {
        float: left;
    }

    .find-us {
        float: left;
    }
}

.sign-up-top {
    width: 34%;
    float: right;
    font-size: 1em;
    padding-right: 1.5em;
    text-align: left;
    padding-top: 5.5em;
    padding-left: 50px;

    @media screen and (min-width: 768px) and (max-width: 1080px) {
        padding-top: 1em;
        font-size: 0.8em;
    }

    @media only screen and (min-width: 1080px) and (max-width: 1250px) {
        padding-top: 2.5em;
    }

    h2 {
        font-size: 2.2em;
    }

    form {
        margin: 0;
        padding: 0;

        input[type="submit"] {
            float: right;
            width: 25%;
            background: #83B246;
            border-radius: 0;
            font-size: 1.5em;
            padding: 8px 10px;
        }

        input[type="email"] {
            float: left;
            width: 75%;
            border-radius: 0;
            font-size: 1.5em;
            padding: 8px 10px 7px 10px;
            background: rgba(255, 255, 255, 0.8);
        }
    }
}

.mc4wp-alert, .mc4wp-notice {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    margin: 0 !important;
}