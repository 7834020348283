.boxes {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    left: 0;
    z-index: 99998;
    top: 0;
    display:none;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    .popup-box {
        top: 270px;
        left: 50%;
        width: 80%;
        max-width: 1000px;
        min-width: 650px;
        left: 00%;
        right: 0;
        visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
        z-index: 99999;
        -webkit-font-smoothing: subpixel-antialiased;
        -webkit-perspective: 1000;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
        margin: 0 auto;
        > div {
            background: white;
            padding: 20px;
            width: 100%;
            height: 100%;
            min-height: 400px;
        }
        * {
            -webkit-font-smoothing: subpixel-antialiased;
            -webkit-perspective: 1000;
        }
    }
    #opening-hours-popup {
        max-width: 650px;
        > div {
            background: white;
            padding: 20px;
            width: 100%;
            height: 100%;
            min-height: 260px;
        }
    }
    .close {
        position: absolute;
        top: 10px;
        right: 15px;
        -webkit-perspective: 1000;
        background: none;
        z-index: 99999;
        border: 0;
        background: none;
        font-size: 1.1em;
        outline: 0;
        &:hover {
            opacity: 0.6;
            -webkit-transition: all 0.45s ease-in-out;
            -moz-transition: all 0.45s ease-in-out;
            -ms-transition: all 0.45s ease-in-out;
            -o-transition: all 0.45s ease-in-out;
            transition: all 0.45s ease-in-out;
            cursor: pointer;
        }
    }
}

.popup-box > div {
    -moz-transform: translate(0, -40px);
    -ms-transform: translate(0, -40px); 
    -o-transform: translate(0, -40px);
    -webkit-transform: translate(0, -40px);
    opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;  
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.show {
    visibility: visible !important;
    opacity: 1 !important;
    pointer-events: auto;
}

#boxes.show {}

#boxes.show .popup-box > div {
    opacity: 1;
    -moz-transform: translate(0, -40px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    -webkit-transform: translate(0, 0px);
} 

.blog-content {
    padding: 0 20px 20px 20px;
}

#back-to-top {
    position: fixed;
    bottom: 30px;
    display: none;
    right: 30px;
}