  //  ═══╦═════════════════════════════════════╦═══ 
 //      ║     ↓   Global Variables   ↓        ║ 
//    ═══╩═════════════════════════════════════╩═══ 
$primary : #4e5d80;
$secondary : #232d44;
$tertiary : #fff;
$quaternary: #c5c5d7;
$quinary: #313131;
$senary: #6e6e6e;
$brand-green: #83b246;
$brand-grey: #898989;
$main-font : "bebasneueregular";
$main-font_light : 'bebasneuelight';
$main-font_thin : 'bebasneuethin';
$main-font_bold : 'bebasneuebold';
$secondary-font : 'roboto';
$main-font_fancy: 'snellround';
$facebook-color: #5d82d1;
$linkedin-color: #00A0DC;
$pinterest-color: #bd081c;
$googleplus-color: #EB5E4C;
$socialmediaicon-color: #fff;
$twitter-color: #40BFF5;
  //  ═══╦═════════════════════════════════════╦═══ 
 //      ║       ↓   Global Imports   ↓        ║ 
//    ═══╩═════════════════════════════════════╩═══ 

@import "setup/fonts";
@import "setup/reset";

@import "setup/grid";
@import "setup/gridboot";

@import "setup/helper";
@import "setup/animations";

@import "navigation/toolbar";
@import "navigation/footer";

@import "setup/screen";
@import "pages/home";
@import "pages/blog";
@import "setup/sidebar";
@import "pages/single";
@import "pages/contact";
@import "setup/buttons";
@import "pages/gallery";
@import "pages/page1";
@import "pages/coming_soon";
@import "pages/user-content"; 

@import "pages/events"; 
@import "elements/popup";
//@import "woocommerce/woocommerce";



  //  ═══╦═════════════════════════════════════╦═══ 
 //      ║     ↓  Import Modifiers Last ↓      ║ 
//    ═══╩═════════════════════════════════════╩═══ 

@import "setup/modifiers";

@import "setup/test";

@import "responsive/tablet";
@import "responsive/mobile";