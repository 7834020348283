
@import url(http://fonts.googleapis.com/css?family=Roboto);


h1 {
    font-family: $main-font_bold;
    font-size: 3.0em;
    color: $tertiary;
    line-height: 90%;
}

h2 {
    font-family: $main-font_bold;
    font-size: 4.4em;
}

h3 {
    font-family: $main-font_bold;
    font-size: 3.8em;
}

h4 {
    font-family: $main-font_bold;
    font-size: 3em;
    color: $primary;
    line-height: 90%;
}

h5 {
    font-family: $secondary-font;
    font-size: 1.8em;
}

h6 {
    font-family: $main-font_bold;
    font-size: 2.4em;
    color: $tertiary;
}

a {
    color: inherit;
    text-decoration: none;
    //      &:hover {
    //      text-decoration: underline;
    //    }
    &:visited {
        color: inherit;
        text-decoration: none;
    }
}


form {
    margin: 20px 0;
    label {
        font-family: $main-font_bold;
        margin-bottom: 5px;
        font-size: 1.4em;
        display: block;
    }
    textarea,input {
        width: 100%;
        display: block;
        padding: 0.6em 0.75em;
        box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        font-family: $main-font;
        font-size: 1.1em;
        border: 1px solid #ccc;
        margin-bottom: 20px;
    }
    input {}
    textarea {}
    input[type=text] {}
    input[type=submit] {
        background-color: $primary;
        color: #fff;
        width: auto;
        padding: 0.6em 1.25em;
        font-family: $main-font_bold;
        @include button;
    }
}


body {
    font-family: $main-font;
    font-size: 62.5%;
    font-color: $primary;
   background-image:   url("../../img/clouds.png");
    background-color: #aad8fe;
    background-repeat: no-repeat;
    background-position: right top;
    padding:2em 0 0 0;
}

/*#content-home {
      background-image: url("../../img/treeright.png"), url("../../img/treeleft.png");
    background-repeat: no-repeat;
    background-position: right bottom, left bottom;
}

/content foot

#content-blog {
      background-image: url("../../img/treeright.png"), url("../../img/treeleft.png");
    background-repeat: no-repeat;
    background-position: right bottom, left bottom;
}


a.localimage {
  font
}

#content-single {
      background-image: url("../../img/treeright.png"), url("../../img/treeleft.png");
    background-repeat: no-repeat;
    background-position: right bottom, left bottom;
}
*/

//  ÔòÉÔòÉÔòÉÔòªÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòªÔòÉÔòÉÔòÉ
//      Ôòæ         Ôåô   Page Sizing   Ôåô         Ôòæ 
//    ÔòÉÔòÉÔòÉÔò®ÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔò®ÔòÉÔòÉÔòÉ

.page-width {
    width: 1200px;
    margin: 0 auto;
}

//  ÔòÉÔòÉÔòÉÔòªÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòªÔòÉÔòÉÔòÉ
//      Ôòæ      Ôåô   hr and other tags   Ôåô      Ôòæ 
//    ÔòÉÔòÉÔòÉÔò®ÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔò®ÔòÉÔòÉÔòÉ

hr {
    width: 95%;
    height: 1px;
    background-color: $quaternary;
    color: $quaternary;
    margin: 15px 0 15px 0;
    border: 0px;
}

// ----------------------------------------------

//  ÔòÉÔòÉÔòÉÔòªÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòªÔòÉÔòÉÔòÉ
//      Ôòæ         Ôåô   Unconfirmed   Ôåô         Ôòæ 
//    ÔòÉÔòÉÔòÉÔò®ÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔòÉÔò®ÔòÉÔòÉÔòÉ

.pagecontentstyle {
    background: $tertiary;
}

.headertextbackground {
    background-color: rgb(78, 93, 128);
    opacity: 0.702;
    padding: 13px 30px 13px 30px;
    z-index: 1;
}

.CONTENT_BG {
    background-color: rgb(197, 197, 215);
    position: absolute;
    left: 375px;
    top: 592px;
    width: 772px;
    height: 669px;
    z-index: 109;
}

//---------------------------------------------------

.sidebarmailinglisttext {
    font-family: $secondary-font;
    color: rgb(137, 137, 137);
}

.sidebarsubmitbutton {
    background: #83b246;
    padding: 5px 5px 5px 5px;
    margin: 10px 0 5px 0;
}

.sidebarsocialblock {
    margin-top: 4em;
}

.relatedpoststext {
    background: #4e5d80;
    font-family: $main-font-bold;
    font-size: 30px;
    color: white;
    padding: 5px 0 5px 10px;
    margin-top: 1em;
}

.relatedimagesstyle {
    float: left;
}

.relatedposttext {
    background: red;
}

.centered {
    position: absolute;
    left: -50%;
    top: -50%;
}

.image-wrapper {
    position: relative;
    display: inline-block;
}

.image-wrapper img {
    position: relative;
    width: 100%;
    height: auto;
}

.image-wrapper .image-options {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    z-index: 2;
    background: rgba(78, 93, 128, 1);
    border: 1px solid black;
    opacity: 0;
    filter: alpha(opacity = 0);
}

.image-wrapper:hover .image-options {
    opacity: 0.7;
    filter: alpha(opacity = 100);
}

.texttext {
    font-size: 2em;
    color: fff;
    left: 30em;
    &:hover {
        text-decoration: none;
    }
}

.flatyrelimg {
    float: left;
    margin: 10px;
}

.flaty {
    margin: 10px;
}

.heightandsuch {
    height: 200px;
}

.sidebarsocialblock {
    margin-top: 0.5em;
    //@media screen and (max-width: 1400px) {
    text-align: center;
    // }
}

.custom form.search_form #s {
    font-family: arial;
    font-size: 16px;
    background: #ff0000;
    color: #fff;
    border: 5px solid #000;
    padding: 10px;
    width: 210px;
}

.image-collapse_100 {
    width: 100%;
    height: auto !important;
}

.sidebar-paddingon-collapse_15 {
    padding-left: 0;
    @media screen and (max-width: 989px) {
        padding-left: 15px;
    }
}

.photosingle {
    padding: 0;
    .nth {
        background: #c0392b;
        color: #fff;
    }
}

.single-relatedposts_border {
    width: 33%;
}

.related-image_border {
    width: 30%;
    display: inline-block;
    padding: 5px;
    border: 1px solid black;
    border-radius: 4px;
    margin: 5px;
}

.single-related_posts {
    text-align: center;
}

li#menu-item-72 {
    font-size: 1.4em;
}

img.attachment-full-width_image.wp-post-image {
    width: 100%;
    height: auto;
}

img.attachment-category-thumb.wp-post-image {
    width: 100%;
    height: auto;
    float: left;
    margin-bottom: 5px;
}

.photogalleryimage {
    vertical-align: top;
}

.mc4wp-alert {
    font-size: 3em !important;
    text-align: center !important;
}

// Sidebar Newsletter

#sidebar-newsletter {
    input[type=email] {}
    input[type=submit] {}
}

// Footer Newsletter

#footer-newsletter {
    input[type=email] {
        background-color: rgb(197, 197, 215);
        position: absolute;
        width: 67% !important;
        height: 47px;
        z-index: 96;
    }
    input[type=submit] {
        @extend .footernewslettersubmitbutton;
        background: $brand_green !important;
        width: 30% !important;
    }
   ::-webkit-input-placeholder {
      color: black;
   }
   :-moz-placeholder { 
      color: black;  
   }
   ::-moz-placeholder {  
      color: black;  
   }
   :-ms-input-placeholder {  
      color: black;  
   }
    
}

img.attachment-relatedposts_square.wp-post-image {
    width: 100%;
    height: auto;
    float: left;
}

.sidebarmain-text_box {
    background: $primary;
    padding: 10px;
    text-align: center;
}

//.sidebar-search-inputbox {
// background-image: url("../img/search-icon.png");
//background-repeat: no-repeat;
//background-position: right center;
//}

blockquote {
    background-image: url("../../img/quotations.png");
    background-repeat: no-repeat;
    background-position: left center;
    min-height: 100px;
    padding-left: 13em;
}

#wpbody {
    background: red;
}

.single-post-content {
    a {
        color: #83b246;
        text-decoration: underline;
        font-weight: bold;
    }
    ul{
      margin:1em 0;
      font-size:0.9em;
    }
    p{
      font-size:0.9em;
    }
} 

#wpgmaps_directions_edit_1{
width:100%!important;
}

#wpgmaps_directions_editbox_1 table td:first-child{
width:15%!important;
padding:0!important;
margin:0!important;
}


#wpgmaps_directions_editbox_1 table{
width:100%;
}

#wpgmaps_directions_editbox_1 input, #wpgmaps_directions_editbox_1 select{
padding:10px;
margin:0 0 5px 0;
width:80%
}

#wpgmza_show_options_1{
display:none!important;
}
#wpgmaps_directions_editbox_1 input[type=button]{
width:auto;
}