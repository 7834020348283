  //  ═══╦═════════════════════════════════════╦═══ 
 //      ║  ↓   _Gallery.scss Stylesheet  ↓    ║ 
//    ═══╩═════════════════════════════════════╩═══ 

.gallery_background {
	padding: 15px 8px 15px 8px;
	text-align: center;
}

.photosingle {
	padding: 5px 5px 5px 5px;
}

.imagegallery_width {
	width: 33%;
	display: inline-block;
}

.gallery-explore-finlaystone-block {
	width: 24%;
	display: inline-block;
	padding: 5px 5px 5px 5px;
		@media screen and (max-width: 767px) {
			width: 48%;
		}
}

.gallery-image-collapse {
	width: 100%;
}

.gallery-explore-finlaystone_border {
	padding: 5px;
	background: $tertiary;
	border: 1px solid #b8becc;
}

.gallery-explore-finlaystone-block_styling {
	background-color: $primary;
	height: 100%;
	padding: 1.2em 1.2em 1.2em 1.2em;
}

.gallery-image_styling {
	width: 100%;
}

.attachment-thumbnail {
	width: 100%;
}

.gallery-explore-block {
	background: $primary;
	padding: 0.5em;
}

.gallery-explore-block-text {
	font-family: $main-font_bold;
	font-size: 3em;
	color: $tertiary;
	text-align: center;
	@media screen and (min-width: 351px) and (max-width: 520px) {
		font-size: 2em;
	}
	@media screen and (min-width: 0px) and (max-width: 350px) {
		font-size: 1.5em;
	}
}