.user-content {
    a {
        >span {}
    }
    h1 {
        font-size: 3.4em;
        letter-spacing: 0px;
        line-height: 1em;
    }
    h2 {
        font-size: 2.8em;
    }
    h3 {
        font-size: 2.4em;
        margin-bottom: 0.25em;
    }
    h4 {
        font-size: 2em;
        margin-bottom: 0.25em;
    }
    h5 {
        font-size: 1.8em;
        margin-bottom: 0.25em;
    }
    h6 {
        font-size: 1.6em;
        margin-bottom: 0.25em;
    }
    strong {
        font-weight: normal;
        font-family: $main-font_bold;
    }
    blockquote {
        border-top: 4px solid #cacaca;
        border-bottom: 4px solid #cacaca;
        margin: 20px 0 35px 0;
        border-left: 0px;
        background-image: url(../../img/quote.gif);
        background-repeat: no-repeat;
        background-position: 16px 24px;
        padding: 17px 13px 23px 90px;
        p {
            font-size: 1.2em;
            line-height: 1.5;
            /* font-style: italic; */
            font-family: arial;
            padding-bottom: 0;
            line-height: 1.3em;
            font-style: italic;
            font-weight: bold;
        }
    }
    ol,ul {
        margin-bottom: 30px;
        padding: 15px 20px 15px 25px;
        width: 95%;
        margin: 0 auto;
        border: 1px solid #999;
        border-left: 0;
        border-right: 0;
        margin-top: 10px;
        background: rgba(0, 0, 0, 0.05);
        margin-bottom: 30px;
        li {
            font-family: $main-font;
            line-height: 1.45em;
            margin-left: 1.5em;
            list-style-position: inside;
            font-size: 1em;
            padding-bottom: 3px;
            position: relative;
            &:before {
                content: "\e608";
                position: absolute;
                left: -30px;
                top: 1px;
                height: 25px;
                margin-bottom: 2px;
                background: black;
                color: #fff;
                vertical-align: middle;
                font-family: 'icomoon';
            }
        }
    }
    ol {
        counter-reset: preview-ol;
        li {
            list-style: none;
            padding: 3px 0;
            &:before {
                content: counter(preview-ol);
                counter-increment: preview-ol;
                background: #666;
                color: #fff;
                border-radius: 13px;
                width: 26px;
                height: 26px;
                display: inline-block;
                text-align: center;
                margin-right: 30px;
            }
        }
    }
    p {
        padding-bottom: 20px;
        line-height: 1.45em;
    }
    table {
        width: 100%;
        margin-bottom: 25px;
        tr {
            td {
                color: $primary;
                font-family: $main-font_bold;
                font-size: 1em;
            }
        }
        tr.heading {
            background: $primary;
            text-transform: uppercase;
            font-size: 1em;
            td {
                color: #fff;
                font-size: 1.3em;
                background: $primary;
            }
        }
        td,th {
            padding: 10px 12px;
            &:nth-child(1) {}
            &:nth-child(2) {}
            &:nth-child(3) {}
        }
        tbody {
            td {
                border: 1px solid $primary;
                &:nth-child(1) {}
                &:nth-child(2) {}
                &:nth-child(3) {}
            }
            tr {
                &:nth-child(odd) {
                    td {
                        background: #efefef;
                    }
                }
            }
        }
        thead {
            td,th {
                font-size: 1.4em;
            }
        }
        tfoot {
            td,th {
                font-size: 0.8em;
            }
        }
        thead,tfoot {
            tr {
                background: $primary;
                color: #fff;
                border: 1px solid $primary;
                th,td {
                    color: #fff;
                    font-family: $main-font_bold;
                }
            }
        }
    }
    .gallery-item {
        float: left;
        margin-top: 10px;
        text-align: center;
        width: 33%;
    }
}