  //  ═══╦═════════════════════════════════════╦═══
 //      ║        ↓  _sidebar.scss  ↓          ║ 
//    ═══╩═════════════════════════════════════╩═══

  //  ═══╦═════════════════════════════════════╦═══
 //      ║         ↓  Sidebar Blocks ↓         ║ 
//    ═══╩═════════════════════════════════════╩═══

.sidebar-searchblock {
	background-color: $primary;
	padding: 1.2em 1.2em 1.2em 1.2em;
}

.sidebar-mailinglistblock {
	@extend .sidebar-searchblock;
}


  //  ═══╦═════════════════════════════════════╦═══
 //      ║         ↓  Search Box  ↓            ║ 
//    ═══╩═════════════════════════════════════╩═══



.sidebar-search-inputbox {
	border-radius: 0;
	font-size: 2em;
	color: $primary;
	margin: 0 0 0 0;
	  background-image: url("../../img/search-icon.png");
	  background-repeat: no-repeat;
	  background-position: right center;
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║      ↓  Navigational Sliders  ↓     ║ 
//    ═══╩═════════════════════════════════════╩═══

.sidebar-navigation_archives {
	font-family: $main-font_bold;
	font-size: 2em;
	background: $quaternary;
	margin: 1em;
	color: $primary;
}



.sidebar-navigation_boxes {
	background: $quaternary;
	padding: 1.2em;
	margin: 1em 0 0 0;
}

.sidebar-navigation-archive_text {
	color: $primary;
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║     ↓  Mailing List Styles  ↓       ║ 
//    ═══╩═════════════════════════════════════╩═══

.sidebar-mailinglist-inputbox {
	background: $tertiary;
	padding: 1.2em 1.2em 1.2em 1.2em;
	margin: 5px 0 5px 0;
}

.sidebar-mailinglist_text {
	font-family: $secondary-font;
}

.sidebar-submit_button {
	background: $brand-green;
	padding: 5px;
	margin: 10px 0 5px 0;
}

.sidebar-followus_text {
	color: black;
}

form#searchform {
	margin-top: 0;
}

.sidebarmain-2ndelement_header {
	margin-top: 0;
}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║        ↓  NEW & Refactored ↓        ║ 
//    ═══╩═════════════════════════════════════╩═══

.sidebar-navigation_block {
	background-color: $primary;
	padding: 1.2em 1.2em 1.2em 1.2em;
}

.sidebar-navigation-search-form_class {
	margin: 5px 0 0 0;
	width: 100%;
}

#sidebar-archive-dropped, #sidebar-archive-drop, #sidebar-category-dropped, #sidebar-category-drop {
	background: $quaternary;
	margin: 1em 0 0 0;
	padding: 1.2em 1.2em 1.2em 1.2em;
}

#sidebar-archive-drop, #sidebar-category-drop {
	background-image: url("../../img/down-green-caret.png");
	background-repeat: no-repeat;
	background-position: right center;
}

#sidebar-archive-dropped, #sidebar-category-dropped, .sidebar-widget .drop {
    padding: 15px;
    display: none;
    ul{
    	li{
    		position: relative;
    		padding-left:15px;
    		font-size:1.6em;

    		       &:after{ 
    		       	background:$primary;
    		        width:10px;
    		        height:10px;
    		        margin-bottom:5px;
    		        position:absolute;
    		        top:5px;
    		        background-repeat:no-repeat;
    		        left:0px;
    		        content:"";
    		        }
    	}
    }
}
 
.sidebar-archive-navigation_text {
	font-family: $main-font_bold;
	font-size: 2.4em;
	color: $primary;
	text-align: left;
}

.sidebar-category-navigation_text {
	@extend .sidebar-archive-navigation_text;
}

.sidebar-mailinglist_block {
	background: $primary;
	padding: 1.2em;
}

.sidebar-mailinglist_header {
	font-family: $main-font_bold;
	font-size: 3.4em;
	text-align: center;
	color: $tertiary;
}

#sidebar-newsletter{
  input[type=email]{
  		background: $tertiary;
  		border-radius: 0px;
  		margin: 0px;
  }  
  input[type=submit]{ 
  		background: $brand_green;
  		margin: 1em 0 0 0;
 		width: 100%;
 		border-radius: 0px;
}
 form {
  margin: 0;
 }
}

.sidebar-socialmedia_block {
	background: $primary;
	padding: 0.5em 0.5em 0.5em 0.5em;
}

.sidebar-socialmedia_header {
	font-family: $main-font_bold;
	font-size: 3.4em;
	color: $tertiary;
	text-align: center;
}

.single-post-content li:before {
  font-family: AppleGothic, "Lucida Sans", Impact, Verdana, sans-serif;
  content: '■';
  color: #4e5d80;
  float: left;
  width: 2em;
  margin-left: 1em;
  margin-top: 0.4em;
  font-size: 60%;
}