@media only screen and (min-width: 1250px) {
    h6 {
        font-size: 2em;
    }
}

@media only screen and (min-width: 1080px) and (max-width: 1250px) {
    .page-width {
        width: 1000px;
    }

    h4 {
        font-size: 2em;
    }

    h5 {
        font-size: 1.3em;
    }

    h6 {
        font-size: 2em;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1080px) {
    .page-width {
        width: 750px;
    }

    h4 {
        font-size: 2em;
    }

    h5 {
        font-size: 1.3em;
    }

    h6 {
        font-size: 2em;
    }

    .borderright {
        border-right: 0px;
    }

    .borderleft {
        border-left: 0px;
    }

    .abouttextboxbottom {
        border-bottom: 2px solid white;
    }

    .dashimageone {
        border-top: 6px solid white;
        border-right: 6px solid white;
        border-bottom: 6px solid white;
        border-left: 6px solid white;
    }

    .dashimagetwo {
        border-top: 6px solid white;
        border-right: 6px solid white;
        border-bottom: 6px solid white;
        border-left: 6px solid white;
    }

    .dashimagethree {
        border-top: 6px solid white;
        border-right: 6px solid white;
        border-bottom: 6px solid white;
        border-left: 6px solid white;
    }

    .dashimagefour {
        border-top: 6px solid white;
        border-right: 6px solid white;
        border-bottom: 6px solid white;
        border-left: 6px solid white;
    }
}

@media only screen and (min-width: 1080px) and (max-width: 1380) {}