@font-face {
    font-family: 'proxima_extrabold';
    src: url("../../fonts/proximanova-extrabold-webfont.eot");
    src: url("../../fonts/proximanova-extrabold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-extrabold-webfont.woff") format("woff"), url("../../fonts/proximanova-extrabold-webfont.ttf") format("truetype"), url("../../fonts/proximanova-extrabold-webfont.svg#proxima_novaextrabold") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'proxima_black';
    src: url("../../fonts/proximanova-black-webfont.eot");
    src: url("../../fonts/proximanova-black-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-black-webfont.woff") format("woff"), url("../../fonts/proximanova-black-webfont.ttf") format("truetype"), url("../../fonts/proximanova-black-webfont.svg#proxima_novablack") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'proxima_bold';
    src: url("../../fonts/proximanova-bold-webfont.eot");
    src: url("../../fonts/proximanova-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-bold-webfont.woff") format("woff"), url("../../fonts/proximanova-bold-webfont.ttf") format("truetype"), url("../../fonts/proximanova-bold-webfont.svg#proxima_nova_rgbold") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'proxima_semibold';
    src: url("../../fonts/proximanova-semibold-webfont.eot");
    src: url("../../fonts/proximanova-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-semibold-webfont.woff") format("woff"), url("../../fonts/proximanova-semibold-webfont.ttf") format("truetype"), url("../../fonts/proximanova-semibold-webfont.svg#proxima_novasemibold") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'proxima';
    src: url("../../fonts/proximanova-regular-webfont.eot");
    src: url("../../fonts/proximanova-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-regular-webfont.woff") format("woff"), url("../../fonts/proximanova-regular-webfont.ttf") format("truetype"), url("../../fonts/proximanova-regular-webfont.svg#proxima_nova_rgregular") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'proxima_light';
    src: url("../../fonts/proximanova-light-webfont.eot");
    src: url("../../fonts/proximanova-light-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/proximanova-light-webfont.woff") format("woff"), url("../../fonts/proximanova-light-webfont.ttf") format("truetype"), url("../../fonts/proximanova-light-webfont.svg#proxima_novalight") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src:url('../../fonts/icomoon.eot?-w74ikv');
    src:url('../../fonts/icomoon.eot?#iefix-w74ikv') format('embedded-opentype'),
        url('../../fonts/icomoon.woff?-w74ikv') format('woff'),
        url('../../fonts/icomoon.ttf?-w74ikv') format('truetype'),
        url('../../fonts/icomoon.svg?-w74ikv#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebasneueregular';
    src: url("../../fonts/bebasneueregular.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebasneuelight';
    src: url("../../fonts/bebasneuelight.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebasneuethin';
    src: url("../../fonts/bebasneuethin.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebasneuebold';
    src: url("../../fonts/bebasneuebold.otf");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-uniE61B:before {
	content: "\e61b";
}
.icon-test:before {
	content: "\e600";
}
.icon-search:before {
	content: "\e601";
}
.icon-minus:before {
	content: "\e619";
}
.icon-plus:before {
	content: "\e61a";
}
.icon-googleplus:before {
	content: "\e602";
}
.icon-facebook:before {
	content: "\e603";
}
.icon-instagram:before {
	content: "\e616";
}
.icon-twitter:before {
	content: "\e604";
}
.icon-feed:before {
	content: "\e605";
}
.icon-flickr:before {
	content: "\e612";
}
.icon-house:before {
	content: "\e611";
}
.icon-arrow-left:before {
	content: "\e613";
}
.icon-arrow-down:before {
	content: "\e606";
}
.icon-arrow-up:before {
	content: "\e607";
}
.icon-arrow-right:before {
	content: "\e608";
}
.icon-arrow-left2:before {
	content: "\e609";
}
.icon-arrow-down2:before {
	content: "\e60a";
}
.icon-arrow-up2:before {
	content: "\e60b";
}
.icon-arrow-right2:before {
	content: "\e60c";
}
.icon-arrow-left3:before {
	content: "\e60d";
}
.icon-arrow-down3:before {
	content: "\e60e";
}
.icon-arrow-up3:before {
	content: "\e614";
}
.icon-uniE615:before {
	content: "\e615";
}
.icon-vimeo:before {
	content: "\e617";
}
.icon-pinterest:before {
	content: "\e60f";
}
.icon-tumblr:before {
	content: "\e618";
}
.icon-linkedin:before {
	content: "\e610";
}

@font-face {
    font-family: 'snellroundhand';
    src: url("../../fonts/snellroundhandscript.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'snellround';
    src: url("../../fonts/snellroundhand.otf");
    font-weight: normal;
    font-style: normal;
}

