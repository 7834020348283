body {
    overflow-x: hidden;
}

*,html,body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,label,fieldset,input,p,blockquote,th,td {
    margin: 0;
    padding: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset,img {
    border: 0;
    max-width: 100%;
}

address,caption,cite,code,dfn,em,strong,th,var {
    font-style: normal;
    font-weight: normal;
}

ol,ul,li {
    list-style: none;
}

caption,th {
    text-align: left;
}

h1,h2,h3,h4,h5,h6 {
    font-size: 100%;
    font-weight: normal;
}

q:before,q:after {
    content: '';
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

a img {
    border: 0;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.alignright {
    float: right;
    margin-left: 20px;
    margin-bottom: 10px;
}

.alignleft {
    float: left;
    margin-right: 20px;
    margin-bottom: 10px;
}

.aligncenter {
    display: block;
    margin: 0 auto 30px auto;
}

.clear-me:before,
.clear-me:after {
    content: "";
    display: table;
}

.clear-me:after {
    clear: both;
}

#nav-toggle, #mobile-nav, #mobile-detect, #ccccc, #nav-toggle {
    display: none;
}