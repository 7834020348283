@media only screen and (min-width: 200px) and (max-width: 768px) {
    .sign-up-top {
        display: none;
    }

    .find-us-box {
        width: 100%;
        text-align: center;
        padding-top: 10px;
        margin-bottom: 10px;

        .telephone, .find-us {
            float: none;
            display: inline-block;
        }
    }

    .single-small-center {
        padding: 0;
    }

    .single-post_title {
        font-size: 4em;
        line-height: 1em;
    }

    .slide-info {
        font-size: 0.4em;
        max-width: 250px !important;
    }

    #logo {
        margin-bottom: 10px;
    }

    #carousel-container {
        height: 300px !important;

        .caroufredsel_wrapper {
            height: 300px !important;

            .carousel-slide {
                height: 300px !important;
            }
        }
    }

    #carousel-container .caroufredsel_wrapper .carousel-slide {
        background-position: center center;
    }

    #carousel-container .slide-info {
        left: 20px;
    }

    #footer .row .col-md-2, #footer .row .col-md-3 {
        margin: 0 0 2em 0;
        max-width: 48%;

        &:nth-child(even) {
            float: right;
            text-align: right;
        }

        &:nth-child(odd) {
            float: left;
            text-align: left;
            clear: both;
        }
    }

    #toolbarnavigationtext {
        display: none !important;
    }

    #nav {
        visibility: hidden;
        margin-top: 0;
        padding: 0 0 0 0;
        height: 0px;
        width: 0px;
    }

    #mobile-nav ul ul {
        .menu-column {
            > a {
                display: none;
            }
        }

        .menu-photo {
            display: none;
        }
    }

    .ui-datepicker-prev, .ui-datepicker-next {
        top: 10px;
    }

    .ui-datepicker-title {
        font-size: 2.5em;
    }

    .footer {
        padding: 0 15px;
    }

    .single-post-content {
        text-align: left;
    }

    #mainimage {
        margin-top: 12em;
    }

    .newsimage {
        padding-left: 1.5em;
    }

    .page-width {
        width: 100%;
    }

    .floaty {
        padding: 5px;
    }

    .singleblogcontenttext {
        font-size: 1em;
    }

    h1 {
        font-size: 2em;
    }

    h2 {}

    h3 {}

    h4 {}

    h5 {}

    h6 {
        font-size: 1.8em;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    #head {
        height: auto;
    }

    table {
        width: 100%;
    }

    #logo {
        margin: 0 auto;
        width: auto;
        height: 60px;
        position: relative;
        max-width: 100%;
        min-width: 0%;
        border: 1px solid $tertiary;
    }

    .nav-location-pin {
        height: 36px;
    }

    .top-bar {
        text-align: center;
    }

    .toolbarfeaturedblock {
        position: absolute;
        right: 0;
        padding: 1em 1.9em 1em 1.9em;
    }

    #nav-toggle {
        color: #fff;
        position: fixed;
        top: 11px;
        left: 10px;
        width: 34px;
        z-index: 99999;
        display: block !important;
        float: left;
        padding: 3px 5px;
        font-size: 16px;
        text-transform: uppercase;
        padding: 5px !important;
        background: $brand_green;

        .nav-line {
            height: 3px;
            width: 100%;
            background: $tertiary;
            content: "";
            margin-bottom: 5px;

            &:last-child {
                margin: 0;
            }
        }
    }

    #head .nav {
        position: fixed;
        top: 0;
        display: none;
    }

    #mobile-nav {
        background: $brand_green;
        position: fixed;
        left: 0;
        top: 0;
        margin: 0;
        display: none;
        overflow-y: auto;

        /* starting point */
        opacity: .3;
        -webkit-transform: translate3d(5%, 0, 0) scale(0.97);
        -moz-transform: translate3d(5%, 0, 0) scale(0.97);
        transform: translate3d(5%, 0, 0) scale(0.97);
    }

    /*#mobile-nav Expanding Open Effect*/
    #mobile-nav.open {
        opacity: 1;
        display: block;
        -webkit-transform: translate3d(0, 0, 0) scale(1);
        -webkit-animation: slideIn .35s ease-in-out;
        -moz-transform: translate3d(0, 0, 0) scale(1);
        -moz-animation: slideIn .35s ease-in-out;
        transform: translate3d(0, 0, 0) scale(1);
        animation: slideIn .35s ease-in-out;
    }

    @-webkit-keyframes slideIn {
        0% {
            opacity: .3;
            -webkit-transform: translate3d(5%, 0, 0) scale(0.97);
        }

        100% {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0) scale(1);
        }
    }

    @-moz-keyframes slideIn {
        0% {
            opacity: .3;
            -moz-transform: translate3d(5%, 0, 0) scale(0.97);
        }

        100% {
            opacity: 1;
            -moz-transform: translate3d(0, 0, 0) scale(1);
        }
    }

    @keyframes slideIn {
        0% {
            opacity: .3;
            transform: translate3d(5%, 0, 0) scale(0.97);
        }

        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0) scale(1);
        }
    }

    #mobile-nav.close {
        opacity: .3;
        -webkit-transform: translate3d(5%, 0, 0) scale(0.97);
        -webkit-animation: slideOut .3s ease-in-out;
        -moz-transform: translate3d(5%, 0, 0) scale(0.97);
        -moz-animation: slideOut .3s ease-in-out;
        transform: translate3d(5%, 0, 0) scale(0.97);
        animation: slideOut .3s ease-in-out;
    }

    @-webkit-keyframes slideOut {
        0% {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0) scale(1);
        }

        100% {
            opacity: .3;
            -webkit-transform: translate3d(5%, 0, 0) scale(0.97);
        }
    }

    @-moz-keyframes slideOut {
        0% {
            opacity: 1;
            -moz-transform: translate3d(0, 0, 0) scale(1);
        }

        100% {
            opacity: .3;
            -moz-transform: translate3d(5%, 0, 0) scale(0.97);
        }
    }

    @keyframes slideOut {
        0% {
            opacity: 1;
            transform: translate3d(0, 0, 0) scale(1);
        }

        100% {
            opacity: .3;
            transform: translate3d(5%, 0, 0) scale(0.97);
        }
    }

    /* CONTENT ANNIMATION */
    .content {
        /* starting point */
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        z-index: 1;
    }

    /*Content Sliding Open Effect*/
    header.open,
    .content.open {
        -webkit-transform: translate3d(75%, 0, 0);
        -webkit-animation: open .5s ease-in-out;
        -moz-transform: translate3d(75%, 0, 0);
        -moz-animation: open .5s ease-in-out;
        transform: translate3d(75%, 0, 0);
        animation: open .5s ease-in-out;
    }

    @-webkit-keyframes open {
        0% {
            -webkit-transform: translate3d(0, 0, 0);
        }

        100% {
            -webkit-transform: translate3d(75%, 0, 0);
        }
    }

    @-moz-keyframes open {
        0% {
            -moz-transform: translate3d(0, 0, 0);
        }

        100% {
            -moz-transform: translate3d(75%, 0, 0);
        }
    }

    @keyframes open {
        0% {
            transform: translate3d(0, 0, 0);
        }

        100% {
            transform: translate3d(75%, 0, 0);
        }
    }

    /*Content Sliding Closed Effect*/
    header.close,
    .content.close {
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-animation: close .3s ease-in-out;
        -moz-transform: translate3d(0, 0, 0);
        -moz-animation: close .3s ease-in-out;
        transform: translate3d(0, 0, 0);
        animation: close .3s ease-in-out;
    }

    @-webkit-keyframes close {
        0% {
            -webkit-transform: translate3d(75%, 0, 0);
        }

        100% {
            -webkit-transform: translate3d(0, 0, 0);
        }
    }

    @-moz-keyframes close {
        0% {
            -moz-transform: translate3d(75%, 0, 0);
        }

        100% {
            -moz-transform: translate3d(0, 0, 0);
        }
    }

    @keyframes close {
        0% {
            transform: translate3d(75%, 0, 0);
        }

        100% {
            transform: translate3d(0, 0, 0);
        }
    }

    #mobile-nav {
        padding: 10px;
        width: 75%;
        padding-left: 15px;
        color: #fff;
        top: 0;
        left: 0;
        height: 100%;
        padding-top: 70px !important;
        z-index: 9999;

        > ul {
            > li {
                font-size: 18px !important;
                margin-bottom: 5px;
                line-height: inherit !important;
                height: inherit !important;
                float: none;
                border-bottom: 1px solid white;
                padding-bottom: 3px;
                font-family: $main-font;
                text-transform: uppercase;
                opacity: 0;

                > ul.sub-menu {
                    margin-left: 10px;
                    border-bottom: 1px solid white;
                    padding-top: 5px;
                    border-top: 1px solid white;

                    > li {}

                    &:last-child {
                        border-bottom: 0;
                    }
                }

                &:hover {
                    .sub-menu {}
                }
            }
        }

        br {
            content: " ";
            display: inline-block;
        }
    }

    .enquire-box {
        border-bottom: 0 !important;
        margin-left: 0;
    }

    a.nav-enquire {
        margin-left: 0;
        padding: 3px 10px;
    }

    .mobile-nav-bottom {
        bottom: 0;
        left: 0;
        padding: 15px 0;
        font-size: 1em;

        .mobile-contact {
            font-family: $main-font;

            li {
                height: 25px;
                line-height: 28px;
                width: 48%;
                font-size: 1.4em;
                float: left;
                opacity: 0;
                padding-right: 0.5em;

                a {
                    font-family: $main-font;
                }

                &:nth-child(even) {
                    float: right;
                }
            }
        }

        .contact-details {
            font-size: 1.4em;
            width: 100%;
            float: left;
            clear: both;
            margin-top: 10px;
            border-top: 1px solid white;
            padding-top: 10px;

            span {
                display: block;
                width: 100%;
                opacity: 0;
            }
        }
    }

    #nav-toggle {
        * {
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
        }

        .nav-line {
            -webkit-transition: all 0.5s linear;
            -o-transition: all 0.5s linear;
            transition: all 0.5s linear;
        }

        .nav-line {
            &:nth-child(2) {
                -webkit-transition: all 0.2s linear;
                -o-transition: all 0.2s linear;
                transition: all 0.2s linear;
            }
        }
    }

    #nav-toggle.open {
        .nav-line {
            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(1) {
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-transform-origin: 0% 0%;
                -moz-transform-origin: 0% 0%;
                -ms-transform-origin: 0% 0%;
                -o-transform-origin: 0% 0%;
                transform-origin: 0% 0%;
            }

            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-transform-origin: 0% 100%;
                -moz-transform-origin: 0% 100%;
                -ms-transform-origin: 0% 100%;
                -o-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
            }
        }
    }

    #mobile-nav.open {
        > ul li {
            opacity: 0;
            visibility: hidden;

            &:nth-child(1) {
                @include animation(menuFadeIn, 0s, 0.35s, linear, 1);
            }

            &:nth-child(2) {
                @include animation(menuFadeIn, 0.1s, 0.35s, linear, 1);
            }

            &:nth-child(3) {
                @include animation(menuFadeIn, 0.2s, 0.35s, linear, 1);
            }

            &:nth-child(4) {
                @include animation(menuFadeIn, 0.3s, 0.35s, linear, 1);
            }

            &:nth-child(5) {
                @include animation(menuFadeIn, 0.4s, 0.35s, linear, 1);
            }

            &:nth-child(6) {
                @include animation(menuFadeIn, 0.5s, 0.35s, linear, 1);
            }

            &:nth-child(7) {
                @include animation(menuFadeIn, 0.6s, 0.35s, linear, 1);
            }

            &:nth-child(8) {
                @include animation(menuFadeIn, 0.7s, 0.35s, linear, 1);
            }
        }

        .social-media-list li {
            opacity: 0;
            visibility: hidden;

            &:nth-child(1) {
                @include animation(menuFadeUp, 0.6s, 0.35s, linear, 1);
            }

            &:nth-child(2) {
                @include animation(menuFadeUp, 0.7s, 0.35s, linear, 1);
            }

            &:nth-child(3) {
                @include animation(menuFadeUp, 0.8s, 0.35s, linear, 1);
            }

            &:nth-child(4) {
                @include animation(menuFadeUp, 0.9s, 0.35s, linear, 1);
            }
        }

        .contact-details {
            span {
                opacity: 0;
                visibility: hidden;

                &:nth-child(1) {
                    @include animation(menuFadeUp, 0.4s, 0.35s, linear, 1);
                }

                &:nth-child(2) {
                    @include animation(menuFadeUp, 0.6s, 0.35s, linear, 1);
                }
            }
        }
    }

    #mobile-nav {
        * {
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
        }

        li, span {
            opacity: 0 !important;
        }
    }

    .mob-slide {}

    .content {
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
    }

    #main-blog-article {
        padding-right: 0 !important;
    }

    #content-blog .blog-single, #content-blog-single-page .blog-single {
        img {
            width: 100%;
            margin-bottom: 10px;
            margin-right: 0;
            height: auto !important;
        }

        h2 {}

        h5 {
            margin-left: 0;
        }
    }

    /* Force table to not be like tables anymore */
    .woocommerce-page table.shop_table,
    .woocommerce-page table.shop_table thead,
    .woocommerce-page table.shop_table tbody,
    .woocommerce-page table.shop_table th,
    .woocommerce-page table.shop_table td,
    .woocommerce-page table.shop_table tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .woocommerce-page table.shop_table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .woocommerce-page table.shop_table tr {
        /*border: 1px solid #d2d3d3; */
    }

    .woocommerce-page table.shop_table td {
        /* Behave like a "row" */
        border: 1px solid #d2d3d3;
        position: relative;
        padding-left: 30% !important;
    }

    body .woocommerce .products .product {
        width: 100% !important;
    }

    .woocommerce-page table.shop_table {
        border: none;
    }

    .woocommerce-page table.shop_table td.product-spacer {
        border-color: #FFF;
        height: 10px;
    }

    .cart_item .quantity, .product-quantity {
        display: none !important;
    }

    .woocommerce-page table.shop_table td:before {
        /* Now like a table header */
        position: absolute;

        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 25%;
        padding-right: 10px;
        white-space: nowrap;
    }

    .col-1, .col-2 {
        width: 100% !important;
    }

    /*
    Label the data
    */
    .woocommerce-page table.shop_table td.product-remove:before {
        content: "DELETE";
    }

    .woocommerce-page table.shop_table td.product-thumbnail:before {
        content: "IMAGE";
    }

    .woocommerce-page table.shop_table td.product-name:before {
        content: "PRODUCT";
    }

    .woocommerce-page table.shop_table td.product-price:before {
        content: "PRICE";
    }

    .woocommerce-page table.shop_table td.product-quantity:before {
        content: "QUANTITY";
    }

    .woocommerce-page table.shop_table td.product-subtotal:before {
        content: "SUBTOTAL";
    }

    .woocommerce-page table.shop_table td.product-total:before {
        content: "TOTAL";
    }

    .woocommerce .quantity,
    .woocommerce #content .quantity,
    .woocommerce-page .quantity,
    .woocommerce-page #content .quantity {
        margin: 0;
    }

    .woocommerce-page table.cart td.actions,
    .woocommerce-page #content table.cart td.actions {
        text-align: left;
        border: 0;
        padding-left: 6px !important;
    }

    .woocommerce-page table.cart td.actions .button.alt,
    .woocommerce-page #content table.cart td.actions .button.alt {
        float: left;
        margin-top: 10px;
    }

    .woocommerce-page table.cart td.actions div,
    .woocommerce-page #content table.cart td.actions div,
    .woocommerce-page table.cart td.actions input,
    .woocommerce-page #content table.cart td.actions input {
        margin-bottom: 10px;
    }

    .woocommerce-page .cart-collaterals .cart_totals {
        float: left;
        width: 100%;
        text-align: left;
    }

    .woocommerce-page .cart-collaterals .cart_totals th,
    .woocommerce-page .cart-collaterals .cart_totals td {
        border: 0 !important;
    }

    .woocommerce-page .cart-collaterals .cart_totals table tr.cart-subtotal td,
    .woocommerce-page .cart-collaterals .cart_totals table tr.shipping td,
    .woocommerce-page .cart-collaterals .cart_totals table tr.total td {
        padding-left: 6px !important;
    }

    .woocommerce-page table.shop_table tr.cart-subtotal td,
    .woocommerce-page table.shop_table tr.shipping td,
    .woocommerce-page table.shop_table tr.total td,
    .woocommerce-page table.shop_table.order_details tfoot th,
    .woocommerce-page table.shop_table.order_details tfoot td {
        padding-left: 6px !important;
        border: 0 !important;
    }

    .woocommerce-page table.shop_table tbody {
        padding-top: 10px;
    }

    .woocommerce .col2-set .col-1,
    .woocommerce-page .col2-set .col-1,
    .woocommerce .col2-set .col-2,
    .woocommerce-page .col2-set .col-2,
    .woocommerce form .form-row-first,
    .woocommerce form .form-row-last,
    .woocommerce-page form .form-row-first,
    .woocommerce-page form .form-row-last {
        float: none;
        width: 100%;
    }

    .woocommerce .order_details ul,
    .woocommerce-page .order_details ul,
    .woocommerce .order_details,
    .woocommerce-page .order_details {
        padding: 0;
    }

    .woocommerce .order_details li,
    .woocommerce-page .order_details li {
        clear: left;
        margin-bottom: 10px;
        border: 0;
    }

    /* make buttons full width, text wide anyway, improves effectiveness */
    #content table.cart td.actions .button,
    .woocommerce #content table.cart td.actions .input-text,
    .woocommerce #content table.cart td.actions input,
    .woocommerce table.cart td.actions .button,
    .woocommerce table.cart td.actions .input-text,
    .woocommerce table.cart td.actions input,
    .woocommerce-page #content table.cart td.actions .button,
    .woocommerce-page #content table.cart td.actions .input-text,
    .woocommerce-page #content table.cart td.actions input,
    .woocommerce-page table.cart td.actions .button,
    .woocommerce-page table.cart td.actions .input-text,
    .woocommerce-page table.cart td.actions input {
        width: 100%;
    }

    /* keep coupon at 50% */
    #content table.cart td.actions .coupon .button,
    .woocommerce #content table.cart td.actions .coupon .input-text,
    .woocommerce #content table.cart td.actions .coupon input,
    .woocommerce table.cart td.actions .coupon .button,
    .woocommerce table.cart td.actions .coupon .input-text,
    .woocommerce table.cart td.actions .coupon input,
    .woocommerce-page #content table.cart td.actions .coupon .button,
    .woocommerce-page #content table.cart td.actions .coupon .input-text,
    .woocommerce-page #content table.cart td.actions .coupon input,
    .woocommerce-page table.cart td.actions .coupon .button,
    .woocommerce-page table.cart td.actions .coupon .input-text,
    .woocommerce-page table.cart td.actions .coupon input {
        width: 48%;
    }

    /* clean up how coupon inputs display */
    #content table.cart td.actions .coupon,
    .woocommerce table.cart td.actions .coupon,
    .woocommerce-page #content table.cart td.actions .coupon,
    .woocommerce-page table.cart td.actions .coupon {
        margin-top: 1.5em;
    }

    #content table.cart td.actions .coupon .input-text,
    .woocommerce table.cart td.actions .coupon .input-text,
    .woocommerce-page #content table.cart td.actions .coupon .input-text,
    .woocommerce-page table.cart td.actions .coupon .input-text {
        margin-bottom: 1em;
    }

    /* remove cross sells, they interfere with flow between cart and cart totals + shipping calculator */
    .woocommerce .cart-collaterals .cross-sells,
    .woocommerce-page .cart-collaterals .cross-sells {
        display: none;
    }

    .dashimageone {
        border-top: 6px solid white;
        border-right: 6px solid white;
        border-bottom: 6px solid white;
        border-left: 6px solid white;
    }

    .dashimagetwo {
        border-top: 6px solid white;
        border-right: 6px solid white;
        border-bottom: 6px solid white;
        border-left: 6px solid white;
    }

    .dashimagethree {
        border-top: 6px solid white;
        border-right: 6px solid white;
        border-bottom: 6px solid white;
        border-left: 6px solid white;
    }

    .dashimagefour {
        border-top: 6px solid white;
        border-right: 6px solid white;
        border-bottom: 6px solid white;
        border-left: 6px solid white;
    }

    .rflf {
        margin-right: 0;
    }

    .related_post {
        float: left;
    }

    .texttext {
        font-size: 1.2em;
    }

    .flatyreimg {
        float: none;
    }

    .blogsingleheader {
        font-size: 3em;
    }
}