  //  ═══╦═════════════════════════════════════╦═══
 //      ║         ↓   _page1.scss   ↓          ║ 
//    ═══╩═════════════════════════════════════╩═══

  //  ═══╦═════════════════════════════════════╦═══
 //      ║       ↓  Page1 Header Block  ↓      ║ 
//    ═══╩═════════════════════════════════════╩═══

.page1-header-image {
	position: relative;
	margin-bottom: 0.8em;
}

.page1-header-image-style {
	width: 100%;
	height: auto;
	display: block;
}

.page1-header-title-background {
	background: rgba($primary, 0.702);
	padding: 1em 1.5em 1em 2em;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
}

.page1-header-title {
	font-size: 2em;
	color: $tertiary;
}

.page1-body-image {

}

  //  ═══╦═════════════════════════════════════╦═══
 //      ║         ↓  Page1 styling  ↓         ║ 
//    ═══╩═════════════════════════════════════╩═══

.page1-background {
	padding: 1.5em;
	text-align: center;
}

.page1-content {
	background: $quaternary;
	margin: 1.5em 0 1.5em 0;
	padding: 1.5em;
}